import React from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  HStack,
  Heading,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import TableRow from "./TableRow";

const PendingEmergency = ({ emergencyData }) => {
  const history = useHistory();

  return (
    <Box
      bg={"#FFFFFF"}
      borderRadius="10px"
      boxShadow="0px 3px 6px #0000003B"
      p={"15px"}
      w={["100%", "100%", "48.5%"]}
    >
      <HStack mb="10px" justifyContent="space-between">
        <Heading fontWeight="bold" fontSize="18px" color="#000">
          Pending Emergency
        </Heading>
        <Button
          size="sm"
          variant="ghost"
          color={"green"}
          cursor={"pointer"}
          onClick={() => history.push("/manager/emergency")}
        >
          View All
        </Button>
      </HStack>
      <Table variant="simple" size="sm" color="#614285">
        <Thead>
          <Tr>
            <Th fontSize={15} fontWeight="medium" textTransform="capitalize">
              Alert From
            </Th>
            <Th
              fontSize={15}
              fontWeight="medium"
              textTransform="capitalize"
              borderLeft={"1px solid grey"}
            >
              Street
            </Th>
            <Th
              fontSize={15}
              fontWeight="medium"
              textTransform="capitalize"
              borderLeft={"1px solid grey"}
            >
              Emergency Type
            </Th>
            <Th
              fontSize={15}
              fontWeight="medium"
              textTransform="capitalize"
              borderLeft={"1px solid grey"}
            >
              Details
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {emergencyData
            ?.map((emergency, index) => (
              <TableRow
                key={emergency._id}
                SN={index + 1}
                alert={emergency?.userId?.tenantName}
                street={emergency?.userId?.houseId?.streetId?.streetName}
                type={emergency?.type}
                details={emergency?.details}
              />
            ))
            .splice(0, 4)}
        </Tbody>
      </Table>
    </Box>
  );
};

export default PendingEmergency;

export const dummyData = [
  {
    _id: 1,
    alert: "Kayode",
    street: "Merrigold",
    type: "Theft",
    details: "View",
  },
  {
    _id: 2,
    alert: "Amaka",
    street: "Merrigold",
    type: "Food",
    details: "View",
  },
  {
    _id: 3,
    alert: "Williams",
    street: "Merrigold",
    type: "Health",
    details: "View",
  },
  {
    _id: 4,
    alert: "Smith",
    street: "Merrigold",
    type: "Sapa",
    details: "View",
  },
];
