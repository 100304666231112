import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { ImQrcode } from "react-icons/im";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { useSelector } from "react-redux";
import Message from "../../../../components/Message";
import { specialRequestCheckout } from "../../../Security-Portal/GuestCheckIn/service/specialRequest";

export const SpecialRequestQRCodeCheckout = () => {
  // const [scan, setScan] = useState(false);
  const [logs, setLog] = useState([]);
  const userId = useSelector((state) => state.auth.user);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [success, setSuccess] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState("");

  const checkoutViaQRScanner = (guestCode) => {
    const payload = { inviteCode: guestCode, userId };
    specialRequestCheckout(payload, () => null, setConfirmationMsg, setSuccess);
  };

  const scanQRCode = (error, result) => {
    if (result) {
      setLog([...logs, result.text]);
      checkoutViaQRScanner(result.text);
    }
  };

  const handleOpen = () => {
    onOpen();
  };
  const handleClose = () => {
    setLog([]);

    onClose();
  };

  const clickBack = () => {
    setSuccess(false);
    setConfirmationMsg("");
  };

  return (
    <>
      <Box cursor="pointer" color="#333" onClick={handleOpen}>
        <ImQrcode size={35} />
      </Box>

      <Modal isCentered motionPreset="bottom" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Check out With QR code</ModalHeader>

          <ModalBody>
            <Text color="#333" fontSize={".9em"}>
              Ensure there is sufficent lighting
            </Text>
            {!success && !confirmationMsg && (
              <Flex my="5">
                <BarcodeScannerComponent width="400px" onUpdate={scanQRCode} />{" "}
              </Flex>
            )}

            {confirmationMsg && (
              <Message message={confirmationMsg} onclickBack={clickBack} />
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              variant="outline"
              size="sm"
              w="100px"
              _focus={{ outline: "none" }}
              bg="transparent"
              color="red.400"
              onClick={handleClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
