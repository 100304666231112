import React, { useEffect, useState } from "react";
import { Button, Input, Text, Box } from "@chakra-ui/react";
import EstateValidationModal from "./estateValidationModal";
import { getEstateID } from "../service";
import { useSelector } from "react-redux";

function EstateID() {
  const userId = useSelector((state) => state.auth.user);
  const [estateId, setEstateId] = useState("");
  const [shouldOpen, setShouldOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [estateIdTenantData, setEstateIdTenantData] = useState(null);

  useEffect(() => {
    if (estateIdTenantData) {
      setShouldOpen(true);
    }
  }, [estateIdTenantData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      userId,
      estateId,
    };
    getEstateID(payload, setEstateIdTenantData, setLoading);
  };

  const handleClose = () => {
    setShouldOpen(false);
  };

  return (
    <Box flexDirection="column" display="flex" marginTop="150px" width="550px">
      <Text color="#949494" fontSize="1em" mb={10}>
        Please enter the estate ID to access this estate gate
      </Text>

      <Input
        _focus={{ outline: "none" }}
        fontSize=".9em"
        color="#614285"
        background="#F2F2F2"
        boxShadow="0px 3px 6px #00000029"
        placeholder="Enter estate ID"
        size="lg"
        name="guestCode"
        value={estateId}
        onChange={(e) => setEstateId(e.target.value)}
      />

      <Button
        _focus={{ outline: "none", color: "#fff" }}
        _hover={{
          outline: "none",
          background: "#A08CA8",
          color: "#fff",
          border: "1px solid #A08CA8",
        }}
        color="#fff"
        bg="#A08CA8"
        cursor="pointer"
        padding="20px 60px"
        size="lg"
        marginRight="20px"
        mt={10}
        p="20px"
        alignSelf="center"
        isLoading={loading}
        onClick={handleSubmit}
      >
        Submit
      </Button>
      <EstateValidationModal
        estateIdTenantData={estateIdTenantData}
        isOpen={shouldOpen}
        handleClose={handleClose}
      />
    </Box>
  );
}

export default EstateID;
