import http, { AUTH_ROUTES } from "../../../../services/api";
import {
  errorNotifier,
  successNotifier,
} from "../../../../components/NotificationHandler";

export const updateEmergency = async (userId, _id, payload) => {
  try {
    const { data } = await http.put(
      AUTH_ROUTES.MANAGER_UPDATE_EMERGENCY(userId, _id),
      { ...payload }
    );

    successNotifier(data.result.message);
  } catch (e) {
    errorNotifier(e?.response?.meassge);
  }
};
