import { _GET_USER_PATH } from "../../../../app/constants";
import { DATA_ROWS } from "../../../../components/CustomTable";
import http, { AUTH_ROUTES } from "../../../../services/api";

export const getEmergency = async (
  userId,
  setLoading,
  setEmergency,
  setTotal = null,
  skip = 0
) => {
  setLoading(true);
  const page = skip * DATA_ROWS.LIMIT;

  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_EMERGENCY(userId, page));

    setEmergency(data?.emergencies);
    setTotal && setTotal(data?.totalEmergencies || 0);
    setLoading(false);
  } catch (error) {
    setEmergency([]);
    setLoading(false);
  } finally {
    setLoading(false);
  }
};
