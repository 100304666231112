import { Button } from "@chakra-ui/button";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { Box, Flex, Text, VStack } from "@chakra-ui/layout";
import { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { loginUser } from "../service/loginUser";
import { _COLORS } from "../../../app/constants";

export const LoginForm = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");

  const handleClick = () => setShow(!show);

  const handleLogin = async () => {
    setLoading(true);
    await loginUser({ phoneNumber, password }, setLoading);
    setLoading(false);
  };

  const NO_SHADOW = { _focus: { boxShadow: "none" } };
  const BTN_STYLE = {
    _hover: { bg: _COLORS.brand },
    bg: _COLORS.brand,
    color: "#fff",
  };
  const SM_SIZE = { fontSize: "1.2em" };
  const OUTLINE_COLOR = { _focus: { outline: `1px solid ${_COLORS.brand}` } };
  return (
    <>
      <VStack spacing="40px" alignItems="normal">
        <Box>
          <Text {...SM_SIZE} margin="10px 0">
            Phone number
          </Text>
          <InputGroup>
            <Input
              type="tel"
              {...OUTLINE_COLOR}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Enter phone number"
              _placeholder={{ color: "gray" }}
            />
          </InputGroup>
        </Box>

        <Box>
          <Flex justifyContent="space-between" alignItems="center">
            <Text {...SM_SIZE} margin="10px 0">
              Password
            </Text>
          </Flex>

          <InputGroup size="md">
            <Input
              pr="4.5rem"
              type={show ? "text" : "password"}
              placeholder="Enter password"
              onChange={(e) => setPassword(e.target.value)}
              {...OUTLINE_COLOR}
              _placeholder={{ color: "gray" }}
            />
            <InputRightElement width="4.5rem">
              <Button
                {...NO_SHADOW}
                _hover={{ bg: "transparent" }}
                bg="transparent"
                h="1.75rem"
                size="sm"
                onClick={handleClick}
              >
                {show ? <FiEyeOff /> : <FiEye />}
              </Button>
            </InputRightElement>
          </InputGroup>
        </Box>
        <Button
          isLoading={loading}
          {...NO_SHADOW}
          onClick={handleLogin}
          {...BTN_STYLE}
        >
          Login
        </Button>
      </VStack>
    </>
  );
};
