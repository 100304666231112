import { Checkbox, Text, Tr } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FullPageLoader from "../../../../components/full-page-loader";
import { getEmergency } from "../services/getEmergency";
import { updateEmergency } from "../services/updateEmergency";
import CustomTable from "../../../../components/CustomTable";
import { _COLORS, _GET_USER_PATH } from "../../../../app/constants";
import { useHistory } from "react-router-dom";

export const EmergencyTab = () => {
  const userId = useSelector((state) => state.auth.user);

  const [emergency, setEmergency] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalEmergency, setTotalEmergency] = useState(0);

  const history = useHistory();

  useEffect(() => {
    getEmergency(userId, setLoading, setEmergency, setTotalEmergency);
  }, [userId]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <>
      <CustomTable head={EMERGENCY_HEAD}>
        {emergency?.map((data, idx) => (
          <Tr key={idx}>
            <CustomTable.Td>
              <Text {...CustomTable.style}>{idx + 1}</Text>
            </CustomTable.Td>
            <CustomTable.Td>
              <CustomTable.LimitedText limit={15} {...CustomTable.style}>
                {data?.userId?.houseId?.name || "nil"}
              </CustomTable.LimitedText>
            </CustomTable.Td>
            <CustomTable.Td>
              <Text {...CustomTable.style}>
                {data?.userId?.tenantPhone || "nil"}
              </Text>
            </CustomTable.Td>
            <CustomTable.Td>
              <CustomTable.LimitedText limit={15} {...CustomTable.style}>
                {data?.userId?.tenantName || "nil"}
              </CustomTable.LimitedText>
            </CustomTable.Td>
            <CustomTable.Td>
              <Text {...CustomTable.style}>
                <CustomTable.LimitedText>
                  {data?.userId?.houseId?.estate?.[0]?.houseNumber || "nil"}
                </CustomTable.LimitedText>
              </Text>
            </CustomTable.Td>
            <CustomTable.Td>
              <Text {...CustomTable.style}>{data?.type || "nil"}</Text>
            </CustomTable.Td>

            <CustomTable.Td>
              <Text
                {...CustomTable.style}
                {...(data?.status?.toLowerCase() === "pending"
                  ? {
                      ...CustomTable.STATUS_BASE_STYLE("#F96400AD"),
                      color: "#F96400AD",
                    }
                  : data?.status?.toLowerCase() === "resolved" ||
                    data?.status?.toLowerCase() === "sorted"
                  ? {
                      ...CustomTable.STATUS_BASE_STYLE("#A6B5E9"),
                      color: "#A6B5E9",
                    }
                  : {
                      ...CustomTable.STATUS_BASE_STYLE(null),
                      color: "#F96400AD",
                    })}
                fontWeight={"bold"}
                // maxWidth={"80px"}
              >
                {data?.status?.toUpperCase()}
              </Text>
            </CustomTable.Td>
            <CustomTable.Td>
              <Text
                cursor={"pointer"}
                onClick={() =>
                  history.push(
                    `/${_GET_USER_PATH()}/emergency/${data?._id}`,
                    data
                  )
                }
                {...CustomTable.style}
                color={_COLORS.brand}
              >
                View
              </Text>
            </CustomTable.Td>
          </Tr>
        ))}
      </CustomTable>
      <CustomTable.Pagination
        length={emergency?.length}
        total={totalEmergency}
        updateTable={async (page) =>
          getEmergency(
            userId,
            setLoading,
            setEmergency,
            setTotalEmergency,
            page
          )
        }
      />
    </>
  );
};

const EMERGENCY_HEAD = [
  "S/N",
  "Name",
  "Tel",
  ,
  "Occupant Name",
  "Address",
  "Emergency Type",
  "Emergency Status",
  "Details",
];
