import React, { useEffect, useState } from "react";
import { Flex, Box, Text } from "@chakra-ui/react";
import NotificationCard from "./component/NotificationCard";
import { getManagerNotifications } from "./service/index";
import { useSelector } from "react-redux";

const NotificationPage = () => {
  const userId = useSelector((state) => state.auth.user);
  const [getNotifications, setNotification] = useState([]);

  useEffect(() => {
    getManagerNotifications(userId, setNotification);
  }, [userId]);

  return (
    <Flex mt="30px">
      <Box
        w={["90%", "90%", "95", "98%"]}
        h={["100vh", "100vh", "90vh", "80vh"]}
      >
        <Box color="white">
          {getNotifications.length > 1 ? (
            [...getNotifications]?.map((content, key) => {
              return (
                <NotificationCard
                  key={key}
                  title={content?.title}
                  description={content?.body}
                  img={content?.image}
                  alt={content?.title}
                />
              );
            })
          ) : (
            <Text fontSize="1.5em" color="#686868" textAlign="center">
              Notifications unavailable
            </Text>
          )}
        </Box>
      </Box>
    </Flex>
  );
};

export default NotificationPage;
