import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { ResidentsAndDependentsChart } from "./ResidentsAndDependentsChart";

export const ResidentsAndDependentsCard = () => {
  return (
    <Box
      bg={"#FFFFFF"}
      borderRadius="10px"
      boxShadow="0px 3px 6px #0000003B"
      p={"15px"}
      w={["100%", "100%", "48.5%"]}
    >
      <Box
        w={["100%", "100%", "100%", "100%"]}
        overflowY={["scroll", "scroll", "hidden"]}
      >
        <Flex
          justify={"space-between"}
          direction={["column", "column", "row"]}
          align={"center"}
        >
          <Heading fontSize={"18px"} color={"#000"}>
            Residents and Dependents
          </Heading>
          <Flex>
            <Flex align={"center"} pb={"0px"}>
              <Box
                h="11px"
                w="11px"
                rounded={"sm"}
                bg={"green"}
                mr="10px"
              ></Box>
              <Text pr={"10px"} color={"#000"}>
                {"Residents"}
              </Text>
            </Flex>
            <Flex align={"center"} pb={"0px"}>
              <Box
                h="11px"
                w="11px"
                rounded={"sm"}
                bg={"orange"}
                mr="10px"
              ></Box>
              <Text pr={"10px"} color={"#000"}>
                {"Dependents"}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Box>
          <ResidentsAndDependentsChart />
        </Box>
      </Box>
    </Box>
  );
};
