import React from "react";
import { Flex, Box, Text, Spacer } from "@chakra-ui/react";

const DashboardCard = ({
  title,
  amount,
  img,
  bgColor,
  alt,
  iconBg,
  iconColor,
}) => {
  return (
    <Box
      bg={bgColor}
      borderRadius="10"
      p={4}
      pr={7}
      m="15px"
      boxShadow="0px 3px 6px #0000003B"
    >
      <Flex mb={3} align={"center"}>
        <Text
          textAlign="left"
          fontSize="15px"
          fontWeight="semibold"
          color={"#000"}
        >
          {title}
        </Text>
        <Spacer />
        <Box boxSize="10" bg={iconBg} rounded={"full"} p={"7px"}>
          {img}
        </Box>
      </Flex>
      <Box>
        <Text fontSize="44" fontWeight="bold" color={"#000"}>
          {amount}
        </Text>
      </Box>
      <Spacer />
    </Box>
  );
};

export default DashboardCard;
