import { Flex, Spinner } from "@chakra-ui/react";
import { _COLORS } from "../app/constants";

export default function FullPageLoader({ bg, h }) {
  return (
    <Flex
      // bg={bg ? bg : "#E5E5E5"}
      justifyContent="center"
      alignItems="center"
      height={h ? h : "100vh"}
      width="100%"
    >
      <Spinner color={_COLORS.black} w="50px" h="50px" speed="0.65s" />
    </Flex>
  );
}
