import { Box, useMediaQuery } from "@chakra-ui/react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { Navigation } from "../components/Nav/Navigation";
import { SideBar } from "../components/Layout/SideBar";
import { PROTECTED_PATHS } from "./constants";

import Security_Notification from "../pages/Security-Portal/Notification";

import UserValidation from "./../pages/Security-Portal/UserValidation/index";
import Emergency from "../pages/Manager-Portal/Emergency";
import EmergencyDetails from "../pages/Manager-Portal/Emergency/subPages/EmergencyDetails";
import GuestList from "../pages/Manager-Portal/GuestList";
import GuestDetails from "../pages/Manager-Portal/GuestList/component/GuestDetails";
import Dashboard from "../pages/Manager-Portal/Dashboard";
import ManagerCheckin from "../pages/Manager-Portal/GuestCheckIn";
import ManagerCheckout from "../pages/Manager-Portal/GuestCheckOut";

const {
  SECURITY_DASHBOARD,
  SECURITY_EMERGENCY,
  SECURITY_EMERGENCY_DETAILS,
  SECURITY_GUEST_CHECK_IN,
  SECURITY_GUEST_CHECK_OUT,
  SECURITY_GUEST_LIST,
  SECURITY_GUEST_DETAILS,
  SECURITY_NOTIFICATION,
  SECURITY_USER_VALIDATION,
} = PROTECTED_PATHS;

export function AuthenticatedSecurityApp({ userType }) {
  const [toggleSide, setToggleSide] = useState(false);
  const [showSidBar, setShowSideBar] = useState(true);
  const [isMobileScreen] = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    if (isMobileScreen) {
      setToggleSide((initial) => !initial);
    }
  }, [isMobileScreen]);

  const handleToggle = () => {
    setToggleSide((initial) => !initial);
  };

  return (
    <Box className="App" display="flex">
      <Box className={`app-container`}>
        <Router>
          <Box display={`${toggleSide ? "none" : "block"}`}>
            {showSidBar ? (
              <SideBar type="security" toggle={handleToggle} />
            ) : (
              ""
            )}
          </Box>
          <Box className="app-right" width="100%">
            {showSidBar ? (
              <Navigation
                toggle={handleToggle}
                sideBarActive={toggleSide}
                showSidBar={showSidBar}
                userType={userType}
                type="security"
              />
            ) : (
              ""
            )}

            <Box
              width="100%"
              maxWidth="100%"
              mt={showSidBar ? "30px" : 0}
              padding={showSidBar ? ["5px", "10px", "20px"] : "0"}
            >
              <Switch>
                <Route
                  path={SECURITY_USER_VALIDATION}
                  exact
                  component={UserValidation}
                />
                <Route path={SECURITY_DASHBOARD} exact component={Dashboard} />
                <Route path={SECURITY_GUEST_LIST} exact component={GuestList} />
                <Route
                  path={SECURITY_GUEST_DETAILS}
                  exact
                  component={GuestDetails}
                />

                <Route path={SECURITY_EMERGENCY} exact component={Emergency} />
                <Route
                  path={SECURITY_EMERGENCY_DETAILS}
                  exact
                  component={EmergencyDetails}
                />

                <Route
                  path={SECURITY_NOTIFICATION}
                  exact
                  component={Security_Notification}
                />
                <Route path={SECURITY_GUEST_CHECK_IN} exact>
                  <ManagerCheckin
                    setShowSideBar={() => setShowSideBar(false)}
                  />
                </Route>
                <Route path={SECURITY_GUEST_CHECK_OUT} exact>
                  <ManagerCheckout
                    setShowSideBar={() => setShowSideBar(false)}
                  />
                </Route>

                <Redirect from="/*" to={SECURITY_DASHBOARD} />
              </Switch>
            </Box>
          </Box>
        </Router>
      </Box>
    </Box>
  );
}
