import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { SpecialRequestCheckin } from "./component/manager special request/SpecialRequestCheckin";
import ManagerGuestCheckIn from "./component/ManagerGuestCheckin";
import { getEstateName } from "./service/guestValidationApi";
import CustomTable from "../../../components/CustomTable";
import { ImageBackgroundContainer } from "../../../components/ImageBackgroundContainer";
import BoxContainer from "../../../components/BoxContainer";
// import { SecurityGuestCheckin } from './component/SecurityGuestCheckin';

const ManagerCheckin = ({ setShowSideBar }) => {
  const userId = useSelector((state) => state.auth.user);
  const [estateName, setEstateName] = useState("Our Estate");
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    setShowSideBar();
  }, [setShowSideBar]);

  useEffect(() => {
    getEstateName(setEstateName, userId);
  }, [userId]);

  return (
    <ImageBackgroundContainer>
      <Tabs variant="unstyled" mt="10" w="90%">
        <BoxContainer mb="20px">
          <TabList display="flex" justifyContent="center">
            <Tab {...CustomTable.COMBINE_TAB_STYLES}>Guest Check in</Tab>
            <Tab {...CustomTable.COMBINE_TAB_STYLES}>
              Special Request Checkin
            </Tab>
          </TabList>
        </BoxContainer>

        <TabPanels>
          <TabPanel px="0">
            <ManagerGuestCheckIn estateName={estateName} />
          </TabPanel>
          <TabPanel px="0">
            <SpecialRequestCheckin estateName={estateName} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </ImageBackgroundContainer>
  );
};

export default ManagerCheckin;
