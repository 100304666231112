import React from "react";
import { Box, Input, Flex, InputGroup, Text } from "@chakra-ui/react";
import { creatCsvFile, downloadFile } from "download-csv";
import { errorNotifier } from "../../../../components/NotificationHandler";
import CustomTable from "../../../../components/CustomTable";

function GuestListHeader({ allGuestList, setFilter }) {
  const handleDownloadCsv = () => {
    let CSVData;

    const headerNames = [
      "SN",
      "GUEST NAME",
      "RESIDENT NAME",
      "STREET NAME",
      "ALLOW COMPANY",
      "CHECK IN",
      "STATUS",
    ];

    CSVData = allGuestList.map((data, idx) => {
      return {
        SN: idx + 1,
        NAME: data?.name || "N/A",
        ADDRESS: data?.address || "N/A",
        STREET_NAME: data?.streets?.streetName || "N/A",
        ALLOW_COMPANY: data?.allowCompany || "N/A",
        ISSUE_TYPE: data?.issueType || "N/A",
        STATUS: data?.status || "N/A",
      };
    });

    if (CSVData?.length < 1) {
      errorNotifier("Data Table is empty");
      return;
    }
    const csvFile = creatCsvFile(CSVData, headerNames);
    downloadFile(csvFile);
  };
  return (
    <Flex
      justifyContent={"space-between"}
      flexDir={["column", "row"]}
      alignItems={"center"}
      gap={["10px", "0px"]}
      w="100%"
    >
      <Box width={["100%", "100%", "100%", "40%"]}>
        <CustomTable.SearchInputField setFilter={setFilter} />
      </Box>

      <Flex flexDir={["column", "row"]} alignItems={"center"} gap="40px">
        <Flex direction={["column", "column", "row", "row"]} gap={["2", ""]}>
          <Box display={"flex"} mx={[0, 0, 2]} alignItems={"center"}>
            <Text fontSize={"12px"} mr={"2"}>
              From
            </Text>
            <InputGroup
              w={["100%", "100%", "85%"]}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Input
                type="date"
                placeholder="From Date"
                color="grey"
                size="md"
                bg="#EDEDED"
                borderRadius={"6px"}
                onChange={(e) =>
                  setFilter((state) => ({
                    ...state,
                    fromDate: e.target.value,
                  }))
                }
              />
            </InputGroup>
          </Box>
          <Box mx={[0, 0, 2]} display={"flex"} alignItems={"center"}>
            <Text fontSize={"12px"} mr={"2"}>
              To
            </Text>
            <InputGroup w={["100%", "100%", "85%"]}>
              <Input
                type="date"
                placeholder="To Date"
                color="grey"
                size="md"
                bg="#EDEDED"
                borderRadius={"6px"}
                onChange={(e) =>
                  setFilter((state) => ({ ...state, toDate: e.target.value }))
                }
              />
            </InputGroup>
          </Box>
        </Flex>
        <CustomTable.DownloadButton onClick={handleDownloadCsv} />
      </Flex>
    </Flex>
  );
}

export default GuestListHeader;
