import React from "react";
import {
  AlertDialog,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
  AlertDialogCloseButton,
  Box,
  Text,
} from "@chakra-ui/react";

export default function CautionAlertDialog({
  onContinue,
  onRefresh,
  bg,
  color,
  small = false,
  mt,
  btnTitle = "null",
  cautionTitle,
  icon = false,
  size,
  btnProps,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const BTN = { _focus: { boxShadow: "none" } };

  const handleClick = () => {
    onClose();
    onRefresh && onRefresh();
    onContinue(true);
  };

  return (
    <>
      {icon ? (
        <Box ml="3PX" cursor="pointer" onClick={onOpen} {...btnProps}>
          {icon}
        </Box>
      ) : (
        <Button
          background={bg ? bg : "#FF6F6F"}
          size={small ? "xs" : "md"}
          marginTop={mt ? mt : "0"}
          color={color || "#fff"}
          borderRadius="3px"
          _focus={{ boxShadow: "none" }}
          _hover={{ bg: bg ? bg : "#FF6F6F" }}
          onClick={onOpen}
          {...btnProps}
        >
          <Text>{btnTitle}</Text>
        </Button>
      )}
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size={size || "md"}
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{cautionTitle}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogFooter>
            <Button {...BTN} ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Button {...BTN} colorScheme="red" onClick={handleClick} ml={3}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
