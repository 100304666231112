import { Input, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import Card from "./Card";
import MyButton from "./MyButton";

const GuestCodeValidationCard = ({ handleClick, btnLoading }) => {
  const [guestCode, setGuestCode] = useState("");

  return (
    <Card bg={"#FFF"}>
      <Text as="h3" fontSize="18px" fontWeight="bold" color={"#000"} pb={"25px"}>
        Guest Code Validation
      </Text>
      <Input
        size="sm"
        borderRadius="6px"
        mb="20px"
        p={"20px"}
        placeholder="Enter code"
        bg={"gray.100"}
        border="none"
        _hover={{ border: "1px solid #614285" }}
        borderColor="#614285"
        value={guestCode}
        onChange={(e) => setGuestCode(e.target.value)}
      />
      <MyButton
        px="35px"
        bg="green"
        color="#fff"
        size="md"
        focus="none"
        _hover={{ bg: "green" }}
        mybtnCont="right"
        isLoading={btnLoading}
        // isDisabled={guestCode === ""}
        onClick={() => {
          handleClick(guestCode);
          setGuestCode("");
        }}
      />
    </Card>
  );
};

export default GuestCodeValidationCard;
