import { Box, Flex, HStack, Stack, Text } from "@chakra-ui/react";
import { useState } from "react";
import { CgArrowLongLeft } from "react-icons/cg";
import { useHistory } from "react-router-dom";
import FullPageLoader from "../../../../components/full-page-loader";
import { _COLORS } from "../../../../app/constants";
import dayjs from "dayjs";

const GuestDetails = (props) => {
  const history = useHistory();
  const [loading] = useState(false);

  const borderStyles = {
    w: "100%",
    bg: "#fff",
    borderRadius: "10px",
    p: "20px",
    pb: "25px",
    flexDir: "column",
    // flex: "1",
  };
  const subHeaderStyle = {
    color: "#7E7C73",
    fontSize: ".9em",
    fontWeight: "500",
  };
  const contentStyle = {
    color: "#140005 ",
    // fontWeight: "600",
  };

  // console.log(props?.location?.state);

  const {
    checkInDate,
    checkOutDate,
    tenants: { tenantName = "nil", tenantPhone = "nil" },
    status,
    oneTimeVisit,
    allowCompany,
    name,
    phone,
    address,
    guestCode,
    houses: { estate },
  } = props.location.state || {};

  return loading ? (
    <FullPageLoader />
  ) : (
    <Stack p="10">
      <HStack py="5" cursor="pointer" onClick={() => history.goBack()}>
        <CgArrowLongLeft size={25} />
        <Text>Back</Text>
      </HStack>
      <Box
        display={["block", "block", "flex"]}
        justifyContent="space-between"
        gap={10}
      >
        <Box
          bg="white"
          py={6}
          px={4}
          mt={4}
          flex={["100%", "100%", "calc(50% - 8px)"]}
          borderRadius={"10px"}
        >
          <Text fontWeight={"bold"} fontSize={"16px"} mb={"4"}>
            Resident Information
          </Text>

          <Text {...subHeaderStyle}> Resident Name</Text>
          <Text {...contentStyle}>{tenantName}</Text>
          <br />

          <Text {...subHeaderStyle}> Phone Number</Text>
          <Text {...contentStyle}>{tenantPhone}</Text>
          <br />

          <Text {...subHeaderStyle}> Street</Text>
          <Text {...contentStyle}>{address}</Text>
          <br />

          <Text {...subHeaderStyle}> House Number</Text>
          <Text {...contentStyle}>{estate?.[0]?.houseNumber}</Text>
        </Box>
        <Box
          bg="white"
          py={6}
          px={4}
          mt={4}
          flex={["100%", "100%", "calc(50% - 8px)"]}
          borderRadius={"10px"}
        >
          <Text fontWeight={"bold"} fontSize={"16px"} mb={"4"}>
            Guest Details
          </Text>
          <Text color={"#7E7C73"} fontSize={"14px"}>
            {" "}
            Guest Name
          </Text>
          <Text {...contentStyle}>{name}</Text>
          <br />

          <Text color={"#7E7C73"} fontSize={"14px"}>
            {" "}
            Phone Number
          </Text>
          <Text {...contentStyle}>{phone}</Text>
          <br />

          <Text color={"#7E7C73"} fontSize={"14px"}>
            {" "}
            Visiting Type
          </Text>
          <Text {...contentStyle}>
            {oneTimeVisit ? "One time visit" : "Custom Visit"}
          </Text>
          <br />

          <Text color={"#7E7C73"} fontSize={"14px"}>
            {" "}
            Allow Company
          </Text>
          <Text {...contentStyle}>{allowCompany ? "Yes" : "No"}</Text>
        </Box>
        <Box
          bg="white"
          py={6}
          px={4}
          mt={4}
          flex={["100%", "100%", "calc(50% - 8px)"]}
          borderRadius={"10px"}
        >
          <Text fontWeight={"bold"} fontSize={"16px"} mb={"4"}>
            Log Details
          </Text>

          <Flex justifyContent={"space-between"}>
            <Box>
              <Text {...subHeaderStyle}> Check In Date</Text>
              <Text {...contentStyle}>
                {checkInDate
                  ? dayjs(checkInDate).format("DD-MMMM-YYYY")
                  : "..."}
              </Text>
              <br />
            </Box>

            <Box>
              <Text textAlign={"center"} {...subHeaderStyle}>
                Status
              </Text>
              <Flex
                color={
                  status.toLowerCase() === "in" ? _COLORS.brand : _COLORS.red
                }
                border={`1px solid ${
                  status.toLowerCase() === "in" ? _COLORS.brand : _COLORS.red
                }`}
                borderRadius={"20px"}
                justifyContent={"center"}
                alignItems={"center"}
                px="6px"
                alignSelf={"normal"}
                flexDir={"column"}
              >
                <Text textAlign={"center"} fontSize={".8em"}>
                  {status}
                </Text>
              </Flex>
            </Box>
          </Flex>

          <Text {...subHeaderStyle}> Check In Time</Text>
          <Text {...contentStyle}>
            {checkInDate ? dayjs(checkInDate).format("h:mm a") : "..."}
          </Text>

          <br />

          <Text {...subHeaderStyle}> Check Out Date</Text>
          <Text {...contentStyle}>
            {checkOutDate ? dayjs(checkOutDate).format("DD-MMMM-YYYY") : "..."}
          </Text>
          <br />

          <Text {...subHeaderStyle}> Check Out Time</Text>
          <Text {...contentStyle}>
            {checkOutDate ? dayjs(checkOutDate).format("h:mm a") : "..."}
          </Text>
          <br />

          <Text {...subHeaderStyle}>Guest Code</Text>
          <Text {...contentStyle}>{guestCode}</Text>
        </Box>
      </Box>
    </Stack>
  );
};

export default GuestDetails;
