import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td as ChakraTd,
  TableContainer,
  Box,
  Text,
  Flex,
  useDisclosure,
  HStack,
  Stack,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
// import { _COLORS } from "../constant";
import { BsStopFill } from "react-icons/bs";

import { FiDownload } from "react-icons/fi";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
// import { LiaTimesSolid } from "react-icons/lia";
import { useEffect, useState } from "react";

import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { _COLORS } from "../app/constants";
import dayjs from "dayjs";
import { AiOutlineSearch } from "react-icons/ai";
import { Tooltip } from "chart.js";
// import { PiArrowRightFill } from "react-icons/pi";

function CustomTable({
  head = [],
  actionType = 0,
  endpoint = [],
  filter,
  DBdata,
  DBsearchID,
  children,
}) {
  const [initialCopy, setInitialCopy] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    // store initial copy data
    setInitialCopy(DBdata);

    setFilteredData(DBdata);
  }, [DBdata]);

  const { search, fromDate, toDate } = filter || {};

  useEffect(() => {
    if (DBdata?.length < 1) return;

    if (!fromDate || !toDate) {
      return;
    }

    const filterByDate = DBdata?.filter((data) => {
      const compareStartMonthAndYear =
        new Date(fromDate).getMonth() >= new Date(data?.startDate).getMonth() &&
        new Date(fromDate).getFullYear() ===
          new Date(data?.startDate).getFullYear();
      const compareEndMonthAndYear =
        new Date(toDate).getMonth() <= new Date(data?.endDate).getMonth() &&
        new Date(toDate).getFullYear() ===
          new Date(data?.endDate).getFullYear();

      return compareStartMonthAndYear && compareEndMonthAndYear;
    });

    if (filterByDate?.length < 1) return;
    setFilteredData(filterByDate);
  }, [DBdata, fromDate, toDate]);

  useEffect(() => {
    // data length from the database (backend)
    if (DBdata?.length < 1) return;

    if (!search && initialCopy?.length > 0) {
      return;
    }

    const filterBySearch = DBdata?.filter((data) => {
      // access object in another object using dot notation if added
      return DBsearchID.split(".")
        .reduce((a, b) => a?.[b], data)
        ?.toLowerCase()
        .includes(search?.toLowerCase());
    });

    if (filterBySearch?.length < 1) return;
    setFilteredData(filterBySearch);
  }, [DBdata, search]);

  // if user clear search input, return to original data
  useEffect(() => {
    if (!search && DBdata?.length !== filteredData?.length)
      setFilteredData(DBdata);
  }, [search, DBdata]);

  // if user clear date input, return to original data
  useEffect(() => {
    if (
      (!fromDate && DBdata?.length !== filteredData?.length) ||
      (!toDate && DBdata?.length !== filteredData?.length)
    )
      setFilteredData(DBdata);
  }, [fromDate, toDate, DBdata]);

  return (
    <Box
      bg="#fff"
      boxShadow={"sm"}
      borderRadius={"20px"}
      pt="10px"
      maxH={"420px"}
      overflow={"scroll"}
      className="custom-table-container"
    >
      <style>
        {`
          /* Hide scrollbar for Chrome, Safari and Opera */
          .custom-table-container::-webkit-scrollbar {
            display: none;
          }

          /* Hide scrollbar for IE, Edge and Firefox */
          .custom-table-container {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
          }
          
          `}
      </style>
      <TableContainer>
        {/* custom stripe color */}
        <style>
          {`
          .css-tdnrhj tr:nth-of-type(odd) td {
            background: #F8F8F8 ;
        }
          `}
        </style>
        <Table variant="striped" colorScheme="brand.500" size="sm">
          <Thead>
            <Tr>
              {head?.map((data) => (
                <Th
                  p="15px"
                  color={_COLORS.black}
                  fontWeight={"600"}
                  key={data}
                >
                  {data}
                </Th>
              ))}
            </Tr>
          </Thead>

          <Tbody>
            {typeof filter === "object" ? children(filteredData) : children}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default CustomTable;

export const DATA_ROWS = {
  LIMIT: 10,
};
const limit = DATA_ROWS.LIMIT;

export const Pagination = ({ updateTable, length = 0, total = 0 }) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [isEmpty, setEmpty] = useState(false);
  const [maxPageLimit] = useState(5);

  useEffect(() => {
    if (length < 1) {
      setEmpty(({}, () => true));
    } else {
      setEmpty(({}, () => false));
    }
  }, [length]);

  const NUMBER_OF_PAGES = Math.ceil(Number(total) / DATA_ROWS.LIMIT);

  const getPageNumbers = () => {
    let currentPage = page;
    let p = NUMBER_OF_PAGES >= maxPageLimit ? maxPageLimit : NUMBER_OF_PAGES;
    let start = Math.floor(currentPage / p) * p;
    return new Array(p).fill().map((_, idx) => start + idx + 1); //get range 1-5, 6-10
  };

  const goBack = () => {
    if (page === 0) return;

    setPage((initial) => initial - 1);
    setLoading(true);
    updateTable(page - 1).then(() => setLoading(false));
  };

  const isLastPage = total && limit * (page + 1) >= total;

  const goNext = () => {
    if (!length) {
      return;
    }

    if (length < limit || isLastPage) {
      return;
    }

    setPage((initial) => initial + 1);
    setLoading(true);
    updateTable(page + 1).then(() => setLoading(false));
  };

  const ARROW_STYLE = {
    borderRadius: "3px",
    bg: _COLORS.brand,
    fontSize: "1.5em",
    color: "#fff",
    _hover: { background: "transparent" },
    _focus: { boxShadow: "none" },
    p: "2px",
    width: "fit-content",
    cursor: "pointer",
  };

  const goToPageX = (page) => {
    setLoading(true);
    setPage(page);
    updateTable(page)
      .then(() => {
        setLoading(false);
      })
      .catch(() => null);
  };

  return (
    <Box p="10px 0px" my="20px">
      <HStack gap="5px" spacing="2px" justifyContent="flex-end">
        <Text fontSize={".9em"} color="#000">
          {/* Page 1 - {limit} of {NUMBER_OF_PAGES} */}
          Page 1 of {NUMBER_OF_PAGES} pages
        </Text>
        <Box {...ARROW_STYLE} onClick={goBack} disabled={page === 0}>
          <BiChevronLeft />
        </Box>
        <Stack direction="row">
          {getPageNumbers()?.map((pageNumber) => {
            let t = pageNumber - 1;
            return pageNumber <= NUMBER_OF_PAGES ? (
              <Text
                // border="1px solid #8080806b"
                // p="5px"
                borderRadius={"5px"}
                fontSize=".8em"
                // paddingX="15px"
                p="2px 10px"
                cursor={"pointer"}
                onClick={() => (page === t ? null : goToPageX(t))}
                bg={page === t ? `${_COLORS.brand}99` : "#fff"}
                color={page === t ? "#fff" : "#000"}
              >
                {pageNumber}
              </Text>
            ) : null;
          })}
        </Stack>
        <Box
          {...ARROW_STYLE}
          onClick={goNext}
          disabled={length < limit || isLastPage}
        >
          <BiChevronRight />
        </Box>
      </HStack>
    </Box>
  );
};

const style = {
  whiteSpace: "break-spaces",
  maxW: "120px",
  lineHeight: "1.8",
  fontWeight: "400",
  color: "#5A5A5A",
};

// TAB STYLES

const TAB_BASE_STYLE = {
  color: "#808080",
  fontWeight: "bold",
};
const ACTIVE_TAB_STYLE = {
  fontWeight: "bold",
  color: _COLORS.brand,
  borderBottom: `5px solid ${_COLORS.brand}`,
  borderBottomLeftRadius: "5px",
  borderBottomRightRadius: "5px",
  boxShadow: "none ",
};

const COMBINE_TAB_STYLES = {
  ...TAB_BASE_STYLE,
  _selected: {
    ...ACTIVE_TAB_STYLE,
  },
};

// END OF TAB STYLES

const DownloadButton = ({ icon: Icon, onClick, px, py }) => {
  return (
    <Button
      bg={_COLORS.brand}
      color={"#fff"}
      py={py}
      px={px}
      w={["100%", "100%", "200px"]}
      mt={["20px", "20px", "0px"]}
      borderRadius={"10px"}
      _hover={{ background: `${_COLORS.brand}90` }}
      _focus={{ background: _COLORS.brand }}
      leftIcon={
        Icon ? <Icon fontSize={"1.5em"} /> : <FiDownload fontSize={"1.5em"} />
      }
      onClick={onClick}
    >
      Download CSV
    </Button>
  );
};

const BrandButton = ({ icon: Icon, noIcon, onClick, children, ...props }) => {
  return (
    <Button
      bg={_COLORS.brand}
      color={"#fff"}
      borderRadius={"10px"}
      _hover={{ background: `${_COLORS.brand}90` }}
      _focus={{ background: _COLORS.brand }}
      {...(noIcon
        ? {}
        : Icon
        ? {
            leftIcon: {
              ...(Icon ? (
                <Icon fontSize={"1.5em"} />
              ) : (
                <FiDownload fontSize={"1.5em"} />
              )),
            },
          }
        : {})}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
};

export const Td = ({ children }) => {
  return (
    <ChakraTd py="12px !important" fontSize={".8em"}>
      {children}
    </ChakraTd>
  );
};

export const filterFunc = (data, filterBy) =>
  data?.[filterBy?.key] ? data?.[filterBy?.key] === filterBy.value : data;

export const STATUS_BASE_STYLE = (color) => ({
  border: `1px solid ${color || "#F96400AD"}`,
  borderRadius: "20px",
  textAlign: "center",
});

export const LimitedText = ({ children, limit = 10, ...props }) => {
  return (
    // <Box>
    //   <Tooltip
    //     // label={`${children
    //     //   ?.toString()
    //     //   ?.split("")
    //     //   ?.slice(0, parseInt(limit))
    //     //   ?.join("")}`}
    //     label="ddd"
    //   >
    <Text {...props}>{`${children
      ?.toString()
      ?.split("")
      ?.slice(0, parseInt(limit))
      ?.join("")}${children?.length > limit ? "..." : ""}`}</Text>
    // </Tooltip>
    // </Box>
  );
};

export const SearchInputField = ({ setFilter, placeholder, ...props }) => {
  return (
    <InputGroup {...props}>
      <InputLeftElement
        pointerEvents="none"
        children={<AiOutlineSearch color="gray" />}
      />
      <Input
        placeholder={placeholder || "Search Resident Name"}
        _placeholder={{
          color: "grey",
          fontSize: ".8rem",
        }}
        color="black"
        outline="none"
        bg={"#F0F0F0"}
        borderRadius={"10px"}
        type="text"
        fontSize={".9rem"}
        _focus={{ outline: "none" }}
        onChange={(e) =>
          setFilter((state) => ({ ...state, search: e.target.value }))
        }
      />
    </InputGroup>
  );
};

CustomTable.SearchInputField = SearchInputField;

CustomTable.BrandButton = BrandButton;

CustomTable.LimitedText = LimitedText;
CustomTable.STATUS_BASE_STYLE = STATUS_BASE_STYLE;
CustomTable.Td = Td;
CustomTable.style = style;
CustomTable.COMBINE_TAB_STYLES = COMBINE_TAB_STYLES;
CustomTable.TAB_BASE_STYLE = TAB_BASE_STYLE;
CustomTable.ACTIVE_TAB_STYLE = ACTIVE_TAB_STYLE;
CustomTable.DownloadButton = DownloadButton;

CustomTable.Pagination = Pagination;
CustomTable.filterFunc = filterFunc;
