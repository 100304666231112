import { Box, Flex, Tr, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import FullPageLoader from "../../../components/full-page-loader";
import { getAllGuestList } from "./service/guestListApi";
import CustomTable from "../../../components/CustomTable";
import { useHistory } from "react-router-dom";
import { PROTECTED_PATHS } from "../../../app/constants";
import { _COLORS } from "../../../app/constants";
import GuestListHeader from "./component/GuestListHeader";
import SafeAreaContainer from "../../../components/SafeAreaContainer";
import BoxContainer from "../../../components/BoxContainer";

const { MANAGER_GUEST_DETAILS } = PROTECTED_PATHS;

const GuestList = () => {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);
  const [allGuestList, setAllGuestList] = useState([]);
  const [totalGustList, setTotalGustList] = useState(0);
  const history = useHistory();

  const [filter, setFilter] = useState({});

  const [page, setPage] = useState(0);

  useEffect(() => {
    getAllGuestList(
      setLoading,
      userId,
      setAllGuestList,
      setTotalGustList,
      page
    );
  }, [userId, page]);

  const headerNames = [
    "SN",
    "GUEST NAME",
    "RESIDENT NAME",
    "STREET NAME",
    "ALLOW COMPANY",
    "CHECK IN",
    "STATUS",
    "ACTION",
  ];
  return loading ? (
    <FullPageLoader />
  ) : (
    <SafeAreaContainer bg="transparent">
      <BoxContainer mb="30px">
        <GuestListHeader allGuestList={allGuestList} setFilter={setFilter} />
      </BoxContainer>
      <Box maxW={"300px"} maxH={"400px"} overflow={"hidden"}></Box>
      <>
        <CustomTable
          filter={filter}
          DBdata={allGuestList}
          DBsearchID={"name"}
          head={headerNames}
        >
          {(data) => {
            return data?.map((data, idx) => (
              <Tr key={idx}>
                <CustomTable.Td>
                  <Text {...CustomTable.style}>{idx + 1}</Text>
                </CustomTable.Td>
                <CustomTable.Td>
                  <CustomTable.LimitedText limit={15} {...CustomTable.style}>
                    {data?.name || "N/A"}
                  </CustomTable.LimitedText>
                </CustomTable.Td>
                <CustomTable.Td>
                  <CustomTable.LimitedText limit={15} {...CustomTable.style}>
                    {data?.address || "N/A"}
                  </CustomTable.LimitedText>
                </CustomTable.Td>
                <CustomTable.Td>
                  <CustomTable.LimitedText limit={15} {...CustomTable.style}>
                    {data?.streets?.streetName || "N/A"}
                  </CustomTable.LimitedText>
                </CustomTable.Td>
                <CustomTable.Td>
                  <Text {...CustomTable.style}>
                    {data?.allowCompany ? "Yes" : "No" || "N/A"}
                  </Text>
                </CustomTable.Td>
                <CustomTable.Td>
                  <Text {...CustomTable.style}>{data?.issueType || "N/A"}</Text>
                </CustomTable.Td>
                <CustomTable.Td>
                  <Text
                    {...CustomTable.style}
                    {...(data?.status?.toLowerCase() === "in" ||
                    data?.status?.toLowerCase() === "guest_check_in"
                      ? {
                          ...CustomTable.STATUS_BASE_STYLE("#AFE4B2"),
                          color: "#1D8C23",
                        }
                      : data?.status?.toLowerCase() === "pending"
                      ? {
                          ...CustomTable.STATUS_BASE_STYLE("#F9640047"),
                          color: "#F96400AD",
                        }
                      : data?.status?.toLowerCase() === "out" ||
                        data?.status?.toLowerCase() === "guest_check_out"
                      ? {
                          ...CustomTable.STATUS_BASE_STYLE("#C63636"),
                          color: "#C63636C2",
                        }
                      : { ...CustomTable.STATUS_BASE_STYLE(null) })}
                    fontWeight="bold"
                    maxWidth="80px"
                  >
                    {data?.status?.toLowerCase() === "guest_check_out"
                      ? "OUT"
                      : data?.status?.toLowerCase() === "guest_check_in"
                      ? "IN"
                      : data?.status || "N/A"}
                  </Text>
                </CustomTable.Td>

                <CustomTable.Td>
                  <Text
                    cursor={"pointer"}
                    onClick={() => history.push(MANAGER_GUEST_DETAILS, data)}
                    {...CustomTable.style}
                    color={_COLORS.brand}
                  >
                    View
                  </Text>
                </CustomTable.Td>
              </Tr>
            ));
          }}
        </CustomTable>
        <CustomTable.Pagination
          length={allGuestList?.length}
          total={totalGustList}
          updateTable={async (page) => setPage(page)}
        />
      </>
    </SafeAreaContainer>
  );
};

export default GuestList;
