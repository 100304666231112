import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import EmergencyChart from "./EmergencyChart";

export const EmergencyCard = ({data}) => {
  return (
    <Box
      bg={"#FFFFFF"}
      borderRadius="10px"
      boxShadow="0px 3px 6px #0000003B"
      p={"15px"}
      w={["100%", "100%", "48.5%"]}
      // flex={1}
    >
      <Flex
        justify={["center", "center", "space-between"]}
        direction={["column", "column", "row"]}
        w={"100%"}
        align={"center"}
        mb={"20px"}
        px={["10px", "10px", "5px"]}
      >
        <Box>
          <Heading fontSize={["16px", "18px"]} color={"#000"}>
            Emergency Chart
          </Heading>
        </Box>
        <Box fontWeight={400} w={["100%", "100%", "55%"]}>
          <Flex>
            {graphHead.map((data) => (
              <Flex align={"center"} pb={"0px"}>
                <Box
                  h="11px"
                  w="11px"
                  rounded={"sm"}
                  bg={data.color}
                  mr="10px"
                ></Box>
                <Text pr={"10px"} color={"#000"}>{data.head}</Text>
              </Flex>
            ))}
          </Flex>
        </Box>
      </Flex>
      <EmergencyChart data={data} />
    </Box>
  );
};

export const graphHead = [
  {
    color: "green",
    head: "Health",
  },
  {
    color: "purple",
    head: "Theft",
  },
  {
    color: "orange",
    head: "Fire",
  },
  {
    color: "blue.100",
    head: "Others",
  },
];
