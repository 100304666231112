import { Box, Text } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/react";
import { nanoid } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getEstateDue } from "../../pages/Manager-Portal/EstateDue/services/estateDueApis";
import { LogOutSvg } from "../../svgs/LogOutSvg";
import { LinkTo } from "../Nav/LinkTo";
import {
  MANAGER_NAV_ITEMS,
  SECURITY_PORTAL_NAV_ITEMS,
} from "./sidebar.constants";
import "./sidebar.css";

export const SideBar = ({ toggle, type = "manager" }) => {
  const userId = useSelector((state) => state.auth.user);
  const [estateInfo, setEstateInfo] = useState([]);

  useEffect(() => {
    getEstateDue(setEstateInfo, userId);
  }, [userId]);

  return (
    <Box className="sidebar">
      <Box className="sidebar-inner">
        <Flex className="brand">
          <Text
            lineHeight={1.4}
            p="10px"
            mt={["0", "1.5"]}
            fontSize={[".9em", "25px"]}
            fontWeight={"900"}
            mb="30px"
          >
            {estateInfo?.estateName}
          </Text>
        </Flex>

        <Flex flex={1} flexDirection="column" gap="8px">
          {/* MANAGER LINKS */}
          {type === "manager" &&
            MANAGER_NAV_ITEMS?.map(({ title, to, icon }) => (
              <LinkTo
                key={nanoid()}
                to={to}
                title={title}
                toggle={toggle}
                id={1}
                iconComponent={icon}
              />
            ))}

          {/* SECURITY LINKS */}
          {type === "security" &&
            SECURITY_PORTAL_NAV_ITEMS?.map(({ title, to, icon }) => (
              <LinkTo
                key={nanoid()}
                to={to}
                title={title}
                toggle={toggle}
                id={1}
                iconComponent={icon}
              />
            ))}
        </Flex>

        <Box size="md" color="#fff" fontSize=".9em">
          <LinkTo
            logout
            type={type}
            to="/log_out"
            title="Logout"
            id={2}
            iconComponent={LogOutSvg}
          />
        </Box>
      </Box>
    </Box>
  );
};
