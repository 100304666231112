import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  ModalCloseButton,
} from "@chakra-ui/react";
import { Children, isValidElement, cloneElement } from "react";

export default function CustomModal({
  title,
  buttonText,
  children,
  bg,
  size,
  height,
  color,
  small = false,
  icon = false,
  customBtn: CustomBtn,
  customBtnIcon,
  ...props
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const childrenWithProps = Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a TS error too.
    if (isValidElement(child)) {
      return cloneElement(child, { onClose });
    }

    return child;
  });

  return (
    <>
      {icon ? (
        <Text cursor="pointer" onClick={onOpen}>
          {icon}
        </Text>
      ) : CustomBtn ? (
        <CustomBtn
          onClick={onOpen}
          {...(customBtnIcon ? { icon: customBtnIcon } : {})}
          width={["100%", "100%", "200px"]}
        >
          {buttonText}
        </CustomBtn>
      ) : (
        <Button
          bg={bg || "#FF897A"}
          color={color || "#fff"}
          borderRadius="3px"
          height={height || small ? "5" : "7"}
          size={size || small ? "xs" : "md"}
          _focus={{ boxShadow: "none" }}
          _hover={{ bg: bg ? bg : "#FF897A" }}
          onClick={onOpen}
          {...props}
        >
          {buttonText}
        </Button>
      )}

      <ChakraModal
        closeOnOverlayClick={true}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent borderRadius={"10px"}>
          <ModalHeader>
            <Text fontSize="m" fontWeight={"bold"} alignSelf="center">
              {title}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody px={8} py={{ base: 6, md: 4, lg: 6 }}>
            {childrenWithProps}
          </ModalBody>
        </ModalContent>
      </ChakraModal>
    </>
  );
}
