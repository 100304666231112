import { Flex, Text, VStack } from "@chakra-ui/react";
import React from "react";
import Card from "./Card";

const GuestCounter = ({ dashData }) => {
  return (
    <Card bg={"#FFF"}>
      <Text
        textAlign="center"
        as="h3"
        fontSize="18px"
        fontWeight="bold"
        color={"#000"}
      >
        Guest Counter
      </Text>
      <Flex align="center" w="100%" h="100%">
        {dashData
          ?.map((data) => (
            <React.Fragment key={data?.id}>
              {data?.key === "guestIn" && (
                <VStack
                  justifyContent="center"
                  color="#000"
                  flex={1}
                  key={data?.id}
                >
                  <Text fontSize="45px" fontWeight="600">
                    {data?.value}
                  </Text>
                  <Text fontSize="16px" fontWeight="500" color={"green"}>
                    Guest In
                  </Text>
                </VStack>
              )}
              <div className="vertical"></div>
              {data?.key === "guestOut" && (
                <VStack justifyContent="center" flex={1} key={data?.id}>
                  <Text fontSize="45px" color="#000" fontWeight="600">
                    {data?.value}
                  </Text>
                  <Text fontSize="16px" fontWeight="500" color={"orange"}>
                    Guest Out
                  </Text>
                </VStack>
              )}
            </React.Fragment>
          ))
          .splice(4, 6)}
      </Flex>
    </Card>
  );
};

export default GuestCounter;
