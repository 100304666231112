import { NavLink } from "react-router-dom";
import { Flex, useMediaQuery } from "@chakra-ui/react";

export const LinkTo = ({
  type = "",
  to,
  classname,
  iconComponent: IconComponent,
  logout,
  title,
  toggle,
}) => {
  const [isMobileScreen] = useMediaQuery("(max-width: 600px)");

  const handleLogout = () => {
    localStorage.removeItem("11#221#");
    window.location.replace(`/login`);
  };

  return logout ? (
    <Flex
      _hover={{
        cursor: "pointer",
        background: "#f2f2f2",
        borderTopLeftRadius: 40,
        fontWeight: "700",
        color: "black",
        borderBottomLeftRadius: 40,
      }}
      fontWeight="700"
      onClick={handleLogout}
      className={`${classname} link`}
    >
      {IconComponent && <IconComponent fontSize="18px" />}
      {title}
    </Flex>
  ) : (
    <NavLink
      activeClassName="active"
      className={`${classname} link`}
      onClick={() => (isMobileScreen ? toggle() : null)}
      target={title === "CheckIn" || title === "CheckOut" ? "_blank" : ""}
      to={to ? to : "/"}
    >
      {IconComponent && <IconComponent fontSize="18px" />}
      {title}
    </NavLink>
  );
};
