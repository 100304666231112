import React from 'react';
import { Td, Tr } from '@chakra-ui/react';

const TableRow = ({  type, street, alert }) => {
  return (
    <Tr bg="#FFF">
      <Td>{alert || "Name"}</Td>
      <Td borderLeft={"1px solid grey"}>{street || "Street"}</Td>
      <Td borderLeft={"1px solid grey"}>{type || "Emergency"}</Td>
      <Td borderLeft={"1px solid grey"} color="green">
        {"View"}
      </Td>
    </Tr>
  );
};

export default TableRow;
