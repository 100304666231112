import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { ImQrcode } from "react-icons/im";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { useSelector } from "react-redux";
import Message from "../../../../../components/Message";
import { validateCode } from "../../../../Security-Portal/GuestCheckIn/service/specialRequest";

export const SpecialRequestQRCodeCheckin = () => {
  // const [scan, setScan] = useState(false);
  const [logs, setLog] = useState([]);
  const userId = useSelector((state) => state.auth.user);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [success, setSuccess] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState("");

  const checkinViaQRScanner = (guestCode) => {
    const payload = { inviteCode: guestCode, userId };
    validateCode(payload, () => null, setSuccess, setConfirmationMsg);
  };

  const scanQRCode = (error, result) => {
    if (result) {
      setLog([...logs, result.text]);
      checkinViaQRScanner(result.text);
    }
  };

  const handleOpen = () => {
    onOpen();
  };
  const handleClose = () => {
    setLog([]);
    onClose();
  };

  const clickBack = () => {
    setSuccess(false);
    setConfirmationMsg("");
  };

  return (
    <>
      <Box cursor="pointer" color="#333" onClick={handleOpen}>
        <ImQrcode size={35} />
      </Box>

      <Modal isCentered motionPreset="bottom" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Check in With QR code</ModalHeader>
          {/* <ModalCloseButton size='sm' /> */}

          <ModalBody>
            <Text color="#333">Ensure there is sufficent lighting</Text>
            {!success && !confirmationMsg && (
              <Flex my="5">
                <BarcodeScannerComponent width="400px" onUpdate={scanQRCode} />{" "}
              </Flex>
            )}

            {confirmationMsg && (
              <Message message={confirmationMsg} onclickBack={clickBack} />
            )}

            {/* <>
              {response.type === 'event' && (
                <EventVerification
                  setSuccess={setSuccess}
                  response={response}
                />
              )}

              {response.type !== 'event' && (
                <ContractorAndVehicleVerification
                  setSuccess={setSuccess}
                  response={response}
                />
              )}
            </> */}

            {/* {confirmationMsg &&
                logs.map((log) => (
                  <Text color={'#333'} key={log}>
                    Your Invite Code is {log}
                  </Text>
                ))} */}
          </ModalBody>

          <ModalFooter>
            <Button
              variant="outline"
              size="sm"
              w="100px"
              _focus={{ outline: "none" }}
              bg="gray.300"
              color="red.400"
              onClick={handleClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
