import React from "react";
import "./App.css";
import FullPageLoader from "./components/full-page-loader";
import { useDispatch, useSelector } from "react-redux";
import { authSetUser } from "./store/actions/authAction";
import jwtDecode from "jwt-decode";
import { UnAuthenticatedApp } from "./app/unAuthenticatedApp";
import { AuthenticatedManagerApp } from "./app/authenticatedManagerApp";
import { AuthenticatedSecurityApp } from "./app/authenticatedSecurityApp";
import { urlBase64ToUint8Array } from "./send-sw-details";
import { baseURL } from "./services/api";
import axios from "axios";
const { useState, useEffect } = React;

function App() {
  const userId = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userType, setUserType] = useState(null);
  const [checkingStatus, setCheckingStatus] = useState(true);
  const WEB_PUSH_KEY = process.env.REACT_APP_BE_WEB_PUSH_PUBLIC_KEY;

  const subscribe = async () => {
    let sw = await navigator.serviceWorker.ready;
    let push = await sw.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(WEB_PUSH_KEY),
    });

    const payload = { subscription: push, userId };

    await axios
      .post(`${baseURL}/push-notification/notifications/subscription`, payload)
      .then(() => null)
      .catch(() => null);
  };

  //send web push details for service worker
  useEffect(() => {
    if (userId) {
      if ("serviceWorker" in navigator) {
        window.addEventListener("load", async () => {
          await navigator.serviceWorker.register("/sw.js");
        });
      }

      subscribe();
    }

    // eslint-disable-next-line;
  }, [WEB_PUSH_KEY, userId]);

  // check if user is authenticated and get his details and store to global state
  useEffect(() => {
    const getAuthData = (id, userType) => {
      dispatch(authSetUser(id));
      setUserType(userType);
      sessionStorage.setItem("UType", userType);
      setIsLoggedIn(true);
      if (checkingStatus) setCheckingStatus(false);
    };

    const deleteTokenAndKickUserOut = () => {
      localStorage.removeItem("11#221#");
      if (checkingStatus) setCheckingStatus(false);
    };

    const token = localStorage?.getItem("11#221#");

    if (token) {
      const decoded = jwtDecode(token);
      const expiryDate = new Date(decoded?.exp * 1000);

      return new Date() > expiryDate
        ? deleteTokenAndKickUserOut()
        : getAuthData(decoded?.userId, decoded?.userType);
    }
    return deleteTokenAndKickUserOut();
  }, [checkingStatus, dispatch]);

  // End check !!

  if (checkingStatus) {
    return <FullPageLoader />;
  }
  if (isLoggedIn) {
    if (userType?.toLowerCase() === "manager") {
      return <AuthenticatedManagerApp userType={userType} />;
    } else if (userType?.toLowerCase() === "security") {
      return <AuthenticatedSecurityApp userType={userType} />;
    }
  } else {
    return <UnAuthenticatedApp />;
  }
}

export default App;
