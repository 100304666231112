import { Flex, Image, Img, Text } from "@chakra-ui/react";
// import logo from "../assets/auth_icon.png";
import house from "../assets/house.png";
import { _COLORS } from "../app/constants";
import dayjs from "dayjs";
import { getEstateName } from "../pages/Manager-Portal/GuestCheckIn/service/guestValidationApi";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const ImageBackgroundContainer = ({ checkout, children }) => {
  const [estateName, setEstateName] = useState("Our Estate");
  const userId = useSelector((state) => state.auth.user);

  useEffect(() => {
    getEstateName(setEstateName, userId);
  }, [userId]);

  return (
    <Flex alignItems={"flex-start"} h="100vh">
      <Flex
        display={["none", "flex"]}
        flex="1"
        w="100%"
        h="100%"
        justifyContent={"center"}
        alignItems="center"
        position={"relative"}
      >
        <Flex
          w="100%"
          h="100%"
          zIndex={"2"}
          position={"absolute"}
          bg={`#1e2b1fbd`}
        />
        <Image
          src={house}
          h="100vh"
          w="100%"
          position="absolute"
          objectFit={"cover"}
          alt="truck"
          zIndex={"1"}
        />
        {/* <Image
          position={"relative"}
          w="500px"
          maxW="500px"
          src={logo}
          alt="logo"
          zIndex={"3"}
        /> */}
        <Text zIndex={"3"} color="#fff" fontSize={"2.5em"} fontWeight={"600"}>
          {estateName}
        </Text>
      </Flex>
      <Flex flex="1" flexDir={"column"}>
        <Flex
          bg="#fff"
          p="30px"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Text fontSize={"1.5em"} fontWeight={"900"}>
            Guest Check {checkout ? "Out" : "In"}
          </Text>
          <Flex flexDir={"row"} alignItems={"center"} gap="20px">
            <Text>{dayjs().format("ddd, MMM, YYYY")}</Text>
            <Text>{dayjs().format("HH:mm")}</Text>
          </Flex>
        </Flex>

        <Flex
          justifyContent="center"
          alignItems={"center"}
          w="100%"
          h={["100%", "80vh"]}
          flexDir={"column"}
        >
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
};
