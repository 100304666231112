import http, { AUTH_ROUTES } from "../../../../services/api";
import { errorNotifier } from "../../../../components/NotificationHandler";
import { DATA_ROWS } from "../../../../components/CustomTable";

// Get GUEST LIST
export const getGuestList = async (
  setLoading,
  userId,
  skip,
  limit,
  setGuestList
) => {
  const page = skip * limit;
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.MANAGER_GET_GUEST_LIST(userId, page, limit));
    setGuestList(data.guests);

    setLoading(false);
  } catch (e) {
    setGuestList([]);

    setLoading(false);
  } finally {
    setLoading(false);
  }
};

// Get ALL GUEST LIST
export const getAllGuestList = async (
  setLoading,
  userId,
  setAllGuestList,
  setTotal = null,
  skip = 0
) => {
  const page = skip;

  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.MANAGER_GET_ALL_GUEST(userId, page));
    setAllGuestList(data.guests);
    setTotal(data?.totalGuests || 0);
    setLoading(false);
  } catch (e) {
    setAllGuestList([]);

    setLoading(false);
  } finally {
    setLoading(false);
  }
};

//GET SPECIFIC TENANT BY PHONE NUMBER
export const getPhoneNumber = async (payload, setTenantData) => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(
      AUTH_ROUTES.SECURITY_PHONE_NUMBER_VALIDATION(
        payload.phone,
        payload.userId
      )
    );

    setTenantData(data);
  } catch (e) {
    setTenantData(null);
    errorNotifier(e.response?.data?.result?.message);
  }
};

export const getTenantDetails = async (userId, tenantCode, setTenantData) => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_TENANT_DATA(userId, tenantCode));

    setTenantData(data.tenants);
  } catch (e) {
    setTenantData([]);
    // errorNotifier(e.response?.data?.result)
    // setLoading(false);
  }
};
