import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Flex } from "@chakra-ui/react";

ChartJS.register(ArcElement, Tooltip, Legend, Title);
export function ResidentsAndDependentsChart() {
  const data = {
    labels: ["Residents", "Dependents"],
    datasets: [
      {
        data: [70, 40],
        backgroundColor: ["green", "orange"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = data.labels[context.dataIndex];
            const value = data.datasets[0].data[context.dataIndex];
            const total = data.datasets[0].data.reduce(
              (acc, curr) => acc + curr,
              0
            );
            const percentage = ((value / total) * 100).toFixed(2);
            return `${label}: ${percentage}%`;
          },
        },
      },
    },
    responsive: true,
    cutoutPercentage: 85,
    maintainAspectRatio: false,
    legend: {
      display: false,
      position: "bottom",
      usePointStyle: "true",
      labels: {
        fontSize: 12,
        padding: 25,
        fontColor: "#6D7278",
        fontFamily: "kanit light",
      },
    },
  };

  return (
    <Flex justify={["center", "center", "start"]} w={"100%"} mt={"50px"}>
      <Pie data={data} options={options} height={"200px"} width={"200px"} />
    </Flex>
  );
}
