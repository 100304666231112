import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getEstateName } from "../../Security-Portal/GuestCheckIn/service/securityGuestValidationApi";
import { selectedTabStyles } from "../../Security-Portal/GuestCheckOut";
import { ManagerGuestCheckOut } from "./components/ManagerGuestCheckout";
import { SpecialRequestCheckoutManager } from "./components/SpecialRequestCheckoutManager";
import CustomTable from "../../../components/CustomTable";
import BoxContainer from "../../../components/BoxContainer";
import { ImageBackgroundContainer } from "../../../components/ImageBackgroundContainer";

export const ManagerCheckout = ({ setShowSideBar }) => {
  const userId = useSelector((state) => state.auth.user);
  const [estateName, setEstateName] = useState("Our Estate");

  useEffect(() => {
    setShowSideBar();
  }, [setShowSideBar]);

  useEffect(() => {
    getEstateName(setEstateName, userId);
  }, [userId]);

  return (
    <ImageBackgroundContainer checkout>
      <Tabs variant="unstyled" mt="10" w="90%">
        <BoxContainer mb="20px">
          <TabList display="flex" justifyContent="center">
            <Tab {...CustomTable.COMBINE_TAB_STYLES}>Guest Check out</Tab>
            <Tab {...CustomTable.COMBINE_TAB_STYLES}>
              Special Request Check out
            </Tab>
          </TabList>
        </BoxContainer>
        <TabPanels>
          <TabPanel px="0">
            <ManagerGuestCheckOut estateName={estateName} />
          </TabPanel>
          <TabPanel px="0">
            <SpecialRequestCheckoutManager estateName={estateName} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </ImageBackgroundContainer>
  );
};

export default ManagerCheckout;
