import { Box, Heading, Text } from "@chakra-ui/layout";
import { useState } from "react";
import { useSelector } from "react-redux";
import { CheckInForm } from "../component/CheckInForm";
import { Success } from "../component/Success";
import { guestValidation, getGuestCode } from "../service/guestValidationApi";
import { ValidationConfirmation } from "../component/ValidationConfirmation";
import { CheckoutContainer } from "../../GuestCheckOut/components/ManagerGuestCheckout";
import { _COLORS } from "../../../../app/constants";

const ManagerGuestCheckIn = ({ estateName }) => {
  const userId = useSelector((state) => state.auth.user);
  const [success, setSuccess] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState("");
  const [guestCode, setGuestCode] = useState("");
  const [invitedGuest, setInvitedGuest] = useState([]);

  const handleSubmit = (e, guestCode) => {
    e.preventDefault();

    const payload = { guestCode, userId };
    guestValidation(payload, setLoading, setConfirmationMsg, setSuccess);
  };

  const handleGetGuest = (e) => {
    e.preventDefault();
    getGuestCode(setLoading, userId, guestCode, setInvitedGuest, setConfirm);
  };

  const onclickBack = () => {
    setConfirmationMsg("");
    setGuestCode("");
    setSuccess(false);
    setConfirm(false);
  };

  return (
    <CheckoutContainer checkIn>
      {!success && (
        <Text fontSize=".9em" marginBottom="30px" color={_COLORS.light_black}>
          Please enter the validation code sent to you to access this estate
          gate
        </Text>
      )}

      <Box className="dd" marginTop="40px">
        {!success && !confirm && (
          <CheckInForm
            handleGetGuest={handleGetGuest}
            loading={loading}
            guestCode={guestCode}
            setGuestCode={setGuestCode}
            handleSubmit={handleSubmit}
          />
        )}

        {!success && confirm && (
          <ValidationConfirmation
            onclickBack={onclickBack}
            guestCode={guestCode}
            loading={loading}
            invitedGuest={invitedGuest}
            setGuestCode={setGuestCode}
            handleSubmit={handleSubmit}
            setConfirmationMsg={setConfirmationMsg}
            confirmationMsg={confirmationMsg}
          />
        )}

        {success && <Success onclickBack={onclickBack} />}
      </Box>
    </CheckoutContainer>
  );
};
export default ManagerGuestCheckIn;
