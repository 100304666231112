import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";

const GuestCounterChart = () => {
  const data = {
    labels: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],

    datasets: [
      {
        label: "In",
        data: [11, 22, 30, 41, 15, 60, 17],
        backgroundColor: "green",
        borderWidth: 1,
        barThickness: 20,
      },
      {
        label: "Out",
        data: [17, 80, 91, 16, 25, 49, 33],
        backgroundColor: "orange",
        borderWidth: 1,
        barThickness: 20,
      },
    ],
  };

  const options = {
    plugins: {
      legend: false,
    },
    scales: {
      y: {
        display: true,
        title: {
          display: false,
        },
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: {
          stepSize: 20,
        },
      },
    },
    responsive: true,
    interaction: {
      intersect: false,
    },
  };

  return <Bar data={data} options={options} />;
};
export default GuestCounterChart;
