import { Button, Box, Input, HStack } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import FullPageLoader from "../../../../components/full-page-loader";
import { textInputStyles } from "../../../../components/CreateHouseForm";
import { _COLORS } from "../../../../app/constants";
// import { QRCodeCheckin } from './qr scanner';

export const CheckInForm = ({
  handleGetGuest,
  loading,
  guestCode,
  setGuestCode,
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return loading ? (
    <FullPageLoader bg="#f2f2f2" h="100%" />
  ) : (
    <Box>
      <HStack gap="3">
        <Input
          ref={inputRef}
          color="#000"
          placeholder="Enter Validation Code"
          size="lg"
          name="guestCode"
          value={guestCode}
          {...textInputStyles}
          onChange={(e) => setGuestCode(e.target.value)}
        />
        {/* <QRCodeCheckin /> */}
      </HStack>

      <Button
        marginTop="20px"
        _focus={{ outline: "none", color: _COLORS.brand }}
        _hover={{
          outline: "none",
          background: "#fff",
          color: "#614285",
          border: `1px solid ${_COLORS.brand}`,
        }}
        isDisabled={guestCode === ""}
        color="#fff"
        bg={_COLORS.brand}
        cursor="pointer"
        padding="20px 60px"
        fontSize=".9em"
        onClick={(e) => handleGetGuest(e)}
        isLoading={loading}
      >
        Submit
      </Button>
    </Box>
  );
};
