import { Flex } from "@chakra-ui/react";
import React from "react";

export default function SafeAreaContainer({ children, ...props }) {
  return (
    <Flex flexDir={"column"} my="10" p="5" bg="#fff" {...props}>
      {children}
    </Flex>
  );
}
