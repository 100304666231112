import { Box, Flex, Heading } from "@chakra-ui/layout";
import { useState } from "react";

import "./navigation.css";
import { useLocation } from "react-router-dom";
import { getComponentTitle } from "./components/GetComponentTitle";
import { AiOutlineAlignLeft } from "react-icons/ai";

export const Navigation = ({ toggle, showSidBar, userType, type }) => {
  const [toggleSide] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;

  const handleToggle = () => {
    toggle(!toggleSide);
  };

  return (
    <Box position="relative">
      <Box className="navigation-inner">
        <Box
          display="grid"
          gridAutoFlow="column"
          width="100%"
          padding="20px 10px"
          alignItems="center"
        >
          <Flex alignItems="center">
            <Box className="toggle-side-bar">
              {showSidBar ? (
                <AiOutlineAlignLeft
                  onClick={handleToggle}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                ""
              )}
            </Box>

            {/* <Flex
              alignItems={"center"}
              justifyContent={"space-between"}
              w="100%"
            > */}
            <Heading color="#000" size="md" marginLeft={["5px", "30px"]}>
              {getComponentTitle(pathname)}
            </Heading>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};
