import { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  Textarea,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import {
  getStreet,
  editStreet,
} from "../pages/Manager-Portal/Street/services/street";
import { labelStyles, textInputStyles } from "./CreateHouseForm";
import { _COLORS } from "../app/constants";

const EditStreetForm = ({ onClose, streetId, onRefresh }) => {
  const userId = useSelector((state) => state.auth.user);
  const [inputLoading, setInputLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    streetName: "",
    streetDescription: "",
  });

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    editStreet(setButtonLoading, userId, streetId, formValues, onClose).then(
      () => onRefresh()
    );
  };

  useEffect(() => {
    getStreet(setInputLoading, userId, streetId, setFormValues);
  }, [streetId, userId]);

  return (
    <FormControl>
      {inputLoading ? (
        <Center>
          <Spinner color="#E54242" w="50px" h="50px" speed="0.65s" />
        </Center>
      ) : (
        <>
          <Center mb={4}>
            <Text color="#140005C2" fontSize={".9em"} textAlign="center">
              Enter street name and description <br /> of the street to easily
              locate
            </Text>
          </Center>
          <form
            id="editStreet"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <FormLabel {...labelStyles}>Street Name</FormLabel>
            <Input
              value={formValues.streetName}
              onChange={handleChange}
              type="text"
              name="streetName"
              mb={2}
              required
              {...textInputStyles}
            />
            <FormLabel {...labelStyles} mt="10px">
              Street Description
            </FormLabel>
            <Textarea
              value={formValues.streetDescription}
              onChange={handleChange}
              type="text"
              name="streetDescription"
              mb={4}
              h={10}
              resize="none"
              required
              {...textInputStyles}
            />
            <Button
              isLoading={buttonLoading}
              bg={_COLORS.brand}
              color="#fff"
              fontWeight="medium"
              type="submit"
              _hover={{ background: _COLORS.brand }}
              _focus={{ background: _COLORS.branackground }}
              onClick={handleSubmit}
            >
              Edit Street
            </Button>
          </form>
        </>
      )}
    </FormControl>
  );
};

export default EditStreetForm;
