import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { EmergencyTab } from "./components/Emergency";
import CustomTable from "../../../components/CustomTable";
import SafeAreaContainer from "../../../components/SafeAreaContainer";
import BoxContainer from "../../../components/BoxContainer";

const Emergency = () => {
  return (
    <SafeAreaContainer bg="transparent">
      <Tabs>
        <BoxContainer>
          <TabList>
            <Tab {...CustomTable.COMBINE_TAB_STYLES}>Emergencies</Tab>
          </TabList>
        </BoxContainer>

        <TabPanels>
          <TabPanel px={0}>
            <EmergencyTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </SafeAreaContainer>
  );
};

export default Emergency;
