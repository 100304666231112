import React, { useState } from "react";
import PhoneNumber from "./components/phoneNumber";
import EstateID from "./components/estateID";
import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { getEstateID } from "./service/index";
import { useSelector } from "react-redux";
import { _COLORS } from "../../../app/constants";
import SafeAreaContainer from "../../../components/SafeAreaContainer";

function UserValidation() {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [estateIdTenantData, setEstateIdTenantData] = useState([]);

  const estateIdHandleSubmit = async (e, estateId) => {
    e.preventDefault();
    setLoading(true);
    await getEstateID(estateId, userId, setEstateIdTenantData, setLoading);
    setLoading(false);
  };

  return (
    <SafeAreaContainer borderRadius="20px" h="80vh">
      <Tabs variant="line">
        <TabList borderBottomWidth="1px">
          <Tab
            _focus={{ boxShadow: "none" }}
            color="#1A1A1A"
            _selected={{
              borderBottom: `4px solid ${_COLORS.brand}`,
              borderBottomRadius: "3px",
            }}
          >
            Phone number
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel p={["0", "0", "auto"]}>
            <Flex justifyContent="center">
              <PhoneNumber />
            </Flex>
          </TabPanel>

          <TabPanel p={["0", "0", "auto"]}>
            <Flex justifyContent="center">
              <EstateID
                estateIdHandleSubmit={estateIdHandleSubmit}
                estateIdTenantData={estateIdTenantData}
                loading={loading}
              />
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </SafeAreaContainer>
  );
}

export default UserValidation;
