import React from "react";
import {
  Text,
  Box,
  ModalContent,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  Flex,
  Divider,
  Image,
  ModalHeader,
} from "@chakra-ui/react";
import { nanoid } from "@reduxjs/toolkit";
import { _COLORS } from "../../../../app/constants";
import {
  labelStyles,
  textInputStyles,
} from "../../../../components/CreateHouseForm";

const UserValidationModal = ({ tenantData, isOpen, handleClose }) => {
  return (
    <Modal size="3xl" isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color={_COLORS.brand}>More Information</ModalHeader>
        <ModalCloseButton />
        <ModalBody paddingLeft="0">
          <Box
            bg="#fff"
            display="flex"
            flexDirection={["column", "row", "row"]}
            justifyContent="space-between"
            textAlign={["center", "left"]}
            alignItems="center"
          >
            <Flex flexDirection="column">
              <Text
                p={5}
                mt={4}
                fontSize="0.8em"
                color="#949494"
                {...labelStyles}
              >
                NAME:
              </Text>
              <Text
                px={5}
                fontSize="0.8em"
                mt={-4}
                {...textInputStyles}
                border="none"
              >
                {tenantData?.data?.result?.data?.tenant[0]?.tenantName ||
                  "not available"}
              </Text>

              <Text
                p={5}
                mt={4}
                fontSize="0.8em"
                color="#949494"
                {...labelStyles}
              >
                PHONE NUMBER:
              </Text>
              <Text
                px={5}
                fontSize="0.8em"
                mt={-4}
                {...textInputStyles}
                border="none"
              >
                {tenantData?.data?.result?.data?.tenant[0]?.tenantPhone ||
                  "not available"}
              </Text>

              <Text p={5} mt={4} fontSize="0.8em" {...labelStyles}>
                HOUSE UNIT NO:
              </Text>
              <Text
                px={5}
                fontSize="0.8em"
                mt={-4}
                {...textInputStyles}
                border="none"
              >
                Flat
                {tenantData?.data?.result?.data?.tenant[0]?.houseUnit ||
                  " not available"}{" "}
                {tenantData?.data?.result?.data?.tenant[0]?.streets
                  ?.streetName || "not available"}
              </Text>
            </Flex>

            <Image
              src={tenantData?.data?.result?.data?.tenant[0]?.profilePicture}
              borderRadius="50%"
              height="180px"
              width="180px"
              mt={20}
              ml={10}
            />
          </Box>
          <Box textAlign={["center", "left"]} bg="#fff">
            <Text p={5} fontSize="0.8em" mb={4} {...labelStyles}>
              FAMILY MEMBER:
            </Text>
            <Flex flexDirection="column">
              <Flex flexDirection={["column", "row"]}>
                {tenantData?.data?.result?.data?.tenantFamily?.map((member) => {
                  return (
                    <Box mt={-5} key={nanoid()}>
                      <Text px={5} fontSize="1.3em" color="#A08CA8">
                        {member?.tenantName || "not available"}
                      </Text>
                      <Text px={5} fontSize="0.9em" color="#C4C4C4">
                        {member?.tenantPhone || "not available"}
                      </Text>
                    </Box>
                  );
                })}
              </Flex>
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UserValidationModal;
