import { Flex } from "@chakra-ui/react";
import React from "react";

export default function BoxContainer({ children, ...props }) {
  return (
    <Flex
      flexDir={["column", "column", "row"]}
      alignItems={"center"}
      justifyContent={"space-between"}
      bg="#fff"
      p="15px"
      borderRadius={"20px"}
      {...props}
    >
      {children}
    </Flex>
  );
}
