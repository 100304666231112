import { DashboardArray } from "../component/DashboardDataArray";
import http, { AUTH_ROUTES } from "../../../../services/api";
import {
  // errorNotifier,
  successNotifier,
} from "../../../../components/NotificationHandler";


export const getCard = async (setLoading, setCard) => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_CARD);

    setCard(data);
    setLoading(false);
  } catch (e) {
    setCard([]);
    // errorNotifier(e.response?.data.result);
    setLoading(false);
  }
};
//DASHBOARD ANALYTICS FOR THE TOP CARDS
export const getDashboard = async (setLoading, userId, setDashData) => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_DASHBOARD(userId));

    const info = DashboardArray.map((val) => {
      return {
        ...val,
        value: data[val.key],
      };
    });
    setDashData(info);
  } catch (e) {
    // errorNotifier(e?.response?.data.result);
    setDashData(DashboardArray);
    setLoading(false);
  }
};

//GET EMERGENCIES HANDLER
export const getEmergencies = async (setLoading, userId, setEmergencies) => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_EMERGENCIES(userId));

    setEmergencies(data.emergencies);
    setLoading(false);
  } catch (e) {
    setEmergencies([]);
    // errorNotifier(e.response?.data.result);
    setLoading(false);
  }
};

//GET NOTICES HANDLER
export const getNotices = async (setLoading, userId, setNotices) => {
  const params = {
    userId,
    limit: "",
    skip: "",
  };
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.MANAGER_GET_NOTICES(params));

    setNotices(data.notices);
    setLoading(false);
  } catch (e) {
    setLoading(false);
  }
};

//GUEST VALIDATION
export const guestValidation = async (payload) => {
  try {
    const data = await http.post(AUTH_ROUTES.GUEST_VALIDATION, payload);
    successNotifier(data?.data?.result?.message);
  } catch (e) {
    // setLoading(false);
    // errorNotifier(e.response?.data?.result?.message);
  }
};

export const getEmergencyChartData = async (
  setLoading,
  userId,
  setEmergencyChartData
) => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.EMERGENCY_CHART_DATA(userId));
    setEmergencyChartData(data);
    setLoading(false);
  } catch (e) {
    // errorNotifier(e.response?.data.result);
    setLoading(false);
  }
};
