import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Flex, SimpleGrid } from "@chakra-ui/react";
import DashboardCard from "./component/DashboardCard";
// import { DashboardArray } from './component/DashboardDataArray';
import PendingEmergency from "./component/PendingEmegency";
import {
  getDashboard,
  getEmergencies,
  getNotices,
  guestValidation,
  getEmergencyChartData,
  getCard,
} from "./service/dashboardApis";
import FullPageLoader from "../../../components/full-page-loader";
import GuestCodeValidationCard from "./component/GuestCodeValidationCard";
import GuestCounter from "./component/GuestCounter";
import Calender from "./component/Calender";
// import Notice from "./component/Notice";
// import AlarmChart from "../../../components/Chart";
import { EmergencyCard } from "./component/EmergencyCard";
import { GuestCounterCard } from "./component/GuestCounterCard";
import { ResidentsAndDependentsCard } from "./component/ResidentsAndDependentsCard";
import { getEmergency } from "../Emergency/services/getEmergency";

const Dashboard = () => {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [dashData, setDashData] = useState([]);
  const [emergency, setEmergency] = useState([]);
  const [notices, setNotices] = useState([]);
  const [card, setCard] = useState([]);
  const [emergencyChartData, setEmergencyChartData] = useState({});

  useEffect(() => {
    getDashboard(setLoading, userId, setDashData);
    getEmergency(userId, setLoading, setEmergency);
    getNotices(setLoading, userId, setNotices);
    getCard(setLoading, setCard);

    getEmergencyChartData(setLoading, userId, setEmergencyChartData);
  }, [userId]);

  console.log(card, "card")
  const handleClick = async (guestCode) => {
    // e.preventDefault()
    setBtnLoading(true);
    await guestValidation({ guestCode, userId });
    setBtnLoading(false);
  };

  return loading ? (
    <FullPageLoader />
  ) : (
    <Flex className="content" p="30px 0px" direction="column" w="100%">
      <SimpleGrid
        pt={[10, 9, 1]}
        columns={{ sm: 1, md: 2, lg: 4 }}
        color="white"
      >
        {dashData
          ?.map((content) => {
            return (
              <DashboardCard
                key={content?.id}
                bgColor={content?.background}
                title={content?.title}
                img={content?.image}
                amount={content?.value || 0}
                alt={content?.title}
                iconBg={content?.iconBg}
                iconColor={content?.iconColor}
              />
            );
          })
          .splice(0, 4)}
      </SimpleGrid>
      <SimpleGrid
        columns={{ sm: 2, md: 2, lg: 3 }}
        gap={["15px", "15px", "0px"]}
      >
        <GuestCodeValidationCard
          handleClick={handleClick}
          btnLoading={btnLoading}
        />
        <GuestCounter dashData={dashData} />
        <Calender />
        {/*<Notice notices={notices} />*/}
      </SimpleGrid>

      <Flex
        justify={"space-between"}
        flexDirection={["column", "column", "row"]}
        pt={"30px"}
        px={"12px"}
        gap={[7, 7, 0]}
      >
        <PendingEmergency
          emergencyData={emergency?.filter(
            (emergency) => emergency?.status === "PENDING"
          )}
        />
        <EmergencyCard data={emergencyChartData} />
      </Flex>
      <Flex
        justify={"space-between"}
        pt={"30px"}
        px={"12px"}
        flexDirection={["column", "column", "row"]}
        gap={[7, 7, 0]}
      >
        <GuestCounterCard />
        <ResidentsAndDependentsCard />
      </Flex>
    </Flex>
  );
};

export default Dashboard;
