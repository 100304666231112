import { Line } from "react-chartjs-2";

const EmergencyChart = ({ data }) => {
  const Data = {
    labels: ["Week 1", "Week 2", "Week 3", "Week 3", "Week 4", "Week 5"],
    datasets: [
      {
        label: "Health",
        data: [10, 20, 15, 25, 30, 22, 18],
        borderColor: "green",
        fill: false,
        pointRadius: 5,
        pointBackgroundColor: "green",
        pointBorderWidth: 2,
        tension: 0.4,
        cubicInterpolationMode: "monotone",
      },
      {
        label: "Theft",
        data: [50, 0, 15, 75, 50, 0, 0],
        borderColor: "purple",
        fill: false,
        pointRadius: 5,
        pointBackgroundColor: "purple",
        pointBorderWidth: 2,
        tension: 0.4,
        cubicInterpolationMode: "monotone",
      },
      {
        label: "Fire",
        data: [70, 20, 55, 100, 40, 12, 18],
        borderColor: "orange",
        fill: false,
        pointRadius: 5,
        pointBackgroundColor: "orange",
        pointBorderWidth: 2,
        tension: 0.4,
        cubicInterpolationMode: "monotone",
      },
      {
        label: "Other",
        data: [40, 20, 75, 100, 40, 32, 30],
        borderColor: "#5596e0",
        fill: false,
        pointRadius: 5,
        pointBackgroundColor: "#5596e0",
        pointBorderWidth: 2,
        tension: 0.4,
        cubicInterpolationMode: "monotone",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true,
        title: {
          display: false,
        },
      },
      y: {
        display: true,
        title: {
          display: false,
        },
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: {
          stepSize: 20,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div>
      <Line style={{ height: "300px" }} data={Data} options={options} />
    </div>
  );
};

export default EmergencyChart;
