/**
 * Paths available to users who are not logged in
 * @constant
 */

import { useSelector } from "react-redux";

export const _COLORS = {
  black: "#000",
  brand: "#1D8C23",
  green: "green",
  red: "red",
  light_black: "#000000a8",
};

export const _GET_USER_PATH = () => {
  const type = sessionStorage.getItem("UType");
  switch (type) {
    case "manager":
      return "manager";
    case "security":
      return "security";
    default:
      return "manager";
  }
};

export const PUBLIC_PATHS = {
  LANDING: "/",
  LOGIN: "/login",
};

/**
 * Paths available to users who are  logged in
 * @constant
 */
export const PROTECTED_PATHS = {
  MANAGER_DASHBOARD: "/manager/dashboard",
  SECURITY_DASHBOARD: "/security/dashboard",

  MANAGER_GUEST_LIST: "/manager/guest-list",
  MANAGER_GUEST_DETAILS: `/${_GET_USER_PATH()}/guest/details`,
  MANAGER_RESIDENT_DETAILS: "/manager/resident/:id",

  SECURITY_GUEST_LIST: "/security/guest-list",
  SECURITY_GUEST_DETAILS: "/security/guest/details",

  MANAGER_EMERGENCY: "/manager/emergency",
  MANAGER_EMERGENCY_DETAILS: "/manager/emergency/:id",

  SECURITY_EMERGENCY: "/security/emergency",
  SECURITY_EMERGENCY_DETAILS: "/security/emergency/:id",

  MANAGER_ISSUE_REPORTS: "/manager/issue-reports",
  MANAGER_ISSUE_REPORT_DETAILS: "/manager/issue-reports/:id",

  MANAGER_NOTIFICATION: "/manager/notification",
  SECURITY_NOTIFICATION: "/security/notification",

  MANAGER_GUEST_CHECK_IN: "/manager/guest-check-in",
  SECURITY_GUEST_CHECK_IN: "/security/guest-check-in",

  MANAGER_GUEST_CHECK_OUT: "/manager/guest-check-out",
  SECURITY_GUEST_CHECK_OUT: "/security/guest-check-out",
  SECURITY_USER_VALIDATION: "/security/user-validation",

  MANAGER_STREET: "/manager/street",
  MANAGER_HOUSE: "/manager/house",
  MANAGER_RESIDENT: "/manager/resident",

  MANAGER_ESTATE_DUE: "/manager/estate-due",
  MANAGER_ESTATE_DUE_ID: "/manager/estate-due/:id",

  MANAGER_NEWS_EVENTS: "/manager/news-events",

  MANGER_EVENT_REQUESTS: "/manager/events-request",
  MANAGER_PAYOUT_HISTORY: "/manager/request-payment-history",

  MANAGER_POLLS: "/manager/polls",
};

/**
 * the number of table rows daat that will be display
 * @constant
 */
