import { useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  FormLabel,
  Input,
  Text,
  Textarea,
  Center,
} from "@chakra-ui/react";
import { createStreet } from "../pages/Manager-Portal/Street/services/street";

const CreateStreetForm = ({ onClose, onRefresh }) => {
  const userId = useSelector((state) => state.auth.user);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    streetName: "",
    streetDescription: "",
    userId: userId,
  });

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createStreet(setButtonLoading, formValues, onClose).then(() => onRefresh());
  };
  return (
    <>
      <Center mb={4}>
        <Text color="#000" textAlign="center" fontSize={"1.2em"}>
          Enter street name and description of the street to easily locate
        </Text>
      </Center>
      <form
        id="editStreet"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <FormLabel color="#000" fontSize={"1.2em"}>
          Street Name
        </FormLabel>
        <Input
          value={formValues.streetName}
          onChange={handleChange}
          type="text"
          name="streetName"
          mb={2}
          // variant="filled"
          color={"#000"}
          required
          bg="#fff"
          border={"1px solid #E6E6E6"}
        />
        <FormLabel color="#000" fontSize={"1.2em"}>
          Street Description
        </FormLabel>
        <Textarea
          value={formValues.streetDescription}
          onChange={handleChange}
          bg="#fff"
          border={"1px solid #E6E6E6"}
          type="text"
          name="streetDescription"
          mb={4}
          color={"#000"}
          // variant="filled"
          // h={10}
          required
          rows={8}
          resize={"none"}
        />
        <Button
          isLoading={buttonLoading}
          bg="#1D8C23"
          color="#fff"
          h={50}
          fontWeight="medium"
          _hover={{ bg: "#1D8C23" }}
          type="submit"
          onClick={handleSubmit}
        >
          Create
        </Button>
      </form>
    </>
  );
};

export default CreateStreetForm;
