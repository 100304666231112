import { _GET_USER_PATH } from "../app/constants";
import { DATA_ROWS } from "../components/CustomTable";

export const AUTH_ROUTES = {
  REGISTER: "/register",
  LOGIN: "/login",
  GET_CARD: "/verify-tenant",

  // GET_MANAGER_NOTIFICATIONS: '/manager/notifcation',
  MANGER_GET_NOTIFICATIONS: (userId) =>
    `/manager/notification/${userId}?sort=desc&limit=20&skip=0`,
  GET_SECURITY_NOTIFICATIONS: "/security/notification",
  SEND_PUSH_NOTIFICATION: `/user/send-push-notification`,
  // GET_RESIDENT: ({ userId, sort = "desc", limit, page }) =>
  //   `manager/tenants/${userId}?limit=${limit}&sort=${sort}&skip=${page}&status=Active`,
  GET_RESIDENT: (userId) => `/manager/manager-tenants/${userId}?sort=desc`,
  GET_ALL_RESIDENT: ({ userId, sort = "desc", page }) =>
    `manager/manager-tenants/${userId}?sort=${sort}&status=Active&limit=${DATA_ROWS.LIMIT}&skip=${page}`,
  UPDATE_RESIDENT: (userId, rowId) => `/manager/tenants/${userId}/${rowId}`,
  CREATE_RESIDENT: `/manager/create-tenant`,
  ADD_FUNDS: "/admin/add-fund",
  USER: "/user",
  GET_TENANT_DATA: (userId, tenantCode) =>
    `/manager/tenants/${userId}?tenantCode=${tenantCode}`,

  //HOUSE ROUTES
  CREATE_HOUSE: "/manager/create-house",

  CREATE_STREET: `/manager/create-street`,
  GET_STREETS: (userId, skip) =>
    `/manager/streets/${userId}?sort=desc&limit=${DATA_ROWS.LIMIT}&skip=${skip}`,
  GET_STREET: (userId, streetId) =>
    `/manager/streets/${userId}?_id=${streetId}`,
  EDIT_STREET: (userId, streetId) => `/manager/streets/${userId}/${streetId}`,
  DELETE_STREET: (userId, streetId) => `/manager/streets/${userId}/${streetId}`,

  //used in house component
  GET_ALL_STREETS: (userId, sort = "asc", limit = "20") =>
    `/manager/streets/${userId}?sort=${sort}&limit=${limit}&skip=0`,

  GUEST_VALIDATION: "/security/check-in",
  MANAGER_GET_NOTICES: ({ userId, sort = "desc", limit = "20", skip = "0" }) =>
    `/${_GET_USER_PATH()}/notice/${userId}?sort=${sort}&limit=${limit}&skip=${skip}`,
  CREATE_EVENTS: "/admin/create-event",
  SECURITY_GET_NOTICES: ({ userId, sort = "desc", limit = "20", skip = "0" }) =>
    `/security/notice/${userId}?sort=${sort}&limit=${limit}&skip=${skip}`,
  GET_DASHBOARD: (userId) =>
    `/${_GET_USER_PATH()}/analytics/${userId}?sort=desc`,

  // manager issues and issues
  GET_ISSUES: (userId, skip) =>
    `/manager/issues/${userId}?sort=asc&limit=${DATA_ROWS.LIMIT}&skip=${skip}`,

  EDIT_ISSUES: (issueId) => `manager/issues/${issueId}`,
  GET_EMERGENCIES: (userId) => "/manager/emergencies?sort=desc",
  EMERGENCY_CHART_DATA: (userId) =>
    `/${_GET_USER_PATH()}/emergency-chart/${userId}`,
  GET_EMERGENCY: (userId, page) =>
    `/${_GET_USER_PATH()}/emergencies/${userId}?limit=${
      DATA_ROWS.LIMIT
    }&skip=${page}&sort=desc`,
  GET_CHAT_MESSAGE: (issueId, managerId) =>
    `manager/issue/chat/${issueId}?userId=${managerId}&limit=${DATA_ROWS.LIMIT}`,
  SEND_CHAT_MESSAGE: "user/issue/chat",

  GET_ESTATE_DUES: (userId, skip) =>
    `/manager/estate-dues-payment/${userId}?sort=desc&skip=${skip}&limit=${DATA_ROWS.LIMIT}`,
  GET_ESTATE_INFO: (userId) =>
    `/${_GET_USER_PATH()}/estate-information/${userId}`,
  POST_ESTATE_DUE: "/admin/create-estate-information",
  // GET_GUEST_LIST: (userId, skip, limit) => `/manager/guests/${userId}?sort=desc&skip=${skip}&limit=${limit}`,

  GET_MANAGER_EMERGENCY_DETAILS: (id) => `/manager/emergencies?id=${id}`,
  GET_SECURITY_EMERGENCY_DETAILS: (userId, id) =>
    `/security/emergencies/${userId}?id=${id}`,

  // SECURITY_PHONE_NUMBER_VALIDATION: "/security/tenants/phonenumber",
  SECURITY_PHONE_NUMBER_VALIDATION: (phoneNumber, userId) =>
    `/security/tenants/phonenumber/${phoneNumber}/${userId}`,
  SECURITY_ESTATE_ID_VALIDATION: (estateId, userId) =>
    `/security/tenants/estateid/${estateId}/${userId}`,

  //checkin checkout
  MANAGER_GUEST_VALIDATION: `/${_GET_USER_PATH()}/check-in?sort=desc`,
  SECURITY_GUEST_VALIDATION: "/security/check-in",
  GET_MANAGER_GUEST_CODE: (userId, guestCode) =>
    `/${_GET_USER_PATH()}/guests/${userId}?guestCode=${guestCode}`,
  GET_SECURITY_GUEST_CODE: (userId, guestCode) =>
    `/security/guests/${userId}?guestCode=${guestCode}`,
  MANAGER_GUEST_CHECKOUT: `/${_GET_USER_PATH()}/check-out`,
  SECURITY_GUEST_CHECKOUT: "/security/check-out",

  //special request chekin checkout
  SPECIAL_REQUEST_CHECKIN: "/security/special-request-checkin",
  SPECIAL_REQUEST_CHEKOUT: "/security/special-request-checkout",

  MANAGER_GET_DASHBOARD: (userId) => `/manager/analytics/${userId}?sort=desc`,
  SECURITY_GET_DASHBOARD: (userId) => `/security/analytics/${userId}?sort=desc`,

  MANAGER_GET_EMERGENCIES: (userId) => "/manager/emergencies?sort=desc",
  SECURITY_GET_EMERGENCIES: (userId) =>
    `/security/emergencies/${userId}?sort=desc&`,

  MANAGER_GET_GUEST_LIST: (userId, skip) =>
    `/manager/guests/${userId}?sort=desc&skip=${skip}&limit=${DATA_ROWS.LIMIT}`,
  SEARCH_GUEST: (searchTerm, userId) =>
    `/user/guestslist/search?userId=${userId}&search=${searchTerm}`,
  MANAGER_GET_ALL_GUEST: (userId, skip) =>
    `/${_GET_USER_PATH()}/guests/${userId}?sort=desc&skip=${skip}&limit=${
      DATA_ROWS.LIMIT
    }`,
  SECURITY_GET_GUEST_LIST: (userId) =>
    `/security/guests/${userId}?sort=desc&limit=${DATA_ROWS.LIMIT}`,
  SECURITY_UPDATE_EMERGENCY_LIST: (userId, emergencyId) =>
    `/security/emergency/${userId}/${emergencyId}`,
  // MANAGER_UPDATE_EMERGENCY_LIST: (emergencyId) =>
  //   `/manager/emergency/${emergencyId}?sort=desc`,
  MANAGER_UPDATE_EMERGENCY: (userId, emergencyId) =>
    `/manager/emergency/${userId}/${emergencyId}`,
  GET_HOUSE: (userId, skip, limit, sort = "asc") =>
    `/manager/houses/${userId}?limit=${limit}&sort=${sort}&skip=${skip}`, //params
  GET_ALL_HOUSE: (userId, skip) =>
    `/manager/houses/${userId}?limit=${DATA_ROWS.LIMIT}&sort=desc&skip=${skip}`, //params
  DELETE_HOUSE: (userId, id) => `/manager/houses/${userId}/${id}?sort=desc`,
  EDIT_HOUSE: (userId, id) => `/manager/houses/${userId}/${id}?sort=desc`,
  SEARCH_HOUSE: (searchTerm, userId) =>
    `/user/houses/search?search=${searchTerm}&userId=${userId}`,

  // manager news-events and announcement
  MANAGER_GET_ANNOUNCEMENT: (userId, skip) =>
    `/manager/announcement/${userId}?skip=${skip}&limit=${DATA_ROWS.LIMIT}`,
  EDIT_ANNOUNCEMENT: (managerId, announcementId) =>
    `/manager/announcement/${managerId}/${announcementId}`,
  CREATE_ANNOUNCEMENT: "/manager/announcement",
  GET_NEW_EVENTS: (userId, page) =>
    `/manager/event/${userId}?sort=desc&limit=${DATA_ROWS.LIMIT}&skip=${page}`,
  MANGER_EDIT_NEWS_EVENT: (userId, eventId) =>
    `/manager/event/${userId}/${eventId}`,
  MANAGER_DELETE_NEWS_EVENT: (userId, eventId) =>
    `/manager/event/${userId}/${eventId}`,
  MANAGER_CREATE_NEWS_EVENT: "/manager/create-event",

  //special requests
  GET_SPECIAL_REQUESTS: (userId, page) =>
    `/manager/special-request/${userId}?type=event&limit=${DATA_ROWS.LIMIT}&skip=${page}`,
  MANAGER_APPROVE_REQUEST: (userId, requestId) =>
    `/manager/special-request/approve/${userId}/${requestId}`,
  MANAGER_DECLINE_REQUEST: (userId, requestId) =>
    `/manager/special-request/decline/${userId}/${requestId}`,
  MANAGER_GET_VENUES: (userId, page) =>
    `/manager/venue/${userId}?&limit=${DATA_ROWS.LIMIT}&skip=${page}`,
  MANAGER_DELETE_VENUE: (userId, venueId) =>
    `manager/venue/${userId}/${venueId}`,
  MANAGER_CREATE_VENUE: "/manager/venue",

  MANAGER_GET_VEHICLE_PAYMENTS: (userId) =>
    `/manager/special-request/${userId}?type=vehicle&requestStatus=approved`,
  MANAGER_GET_CONTRACTOR_AND_ARTISANS_PAYMENT: (userId) =>
    `/manager/special-request/${userId}?type=contractor&requestStatus=approved`,

  MANAGER_SPECIAL_REQUEST_CHECKOUT: "/security/special-request-checkout",
  MANAGER_SET_SPECIAL_REQUEST_PRICE: "/manager/create-estate-information",
  MANAGER_GET_SPECIAL_PRICES: (userId) =>
    `/manager/estate-information/${userId}`,

  //poll
  MANAGER_CREATE_POLL: "/manager/poll",
  MANAGER_GET_POLLS: (userId, page) =>
    `/manager/polls?userId=${userId}&sort=desc&limit=${DATA_ROWS.LIMIT}&skip=${page}`,
  MANAGER_GET_SINGLE_POLL: (userId, pollId) =>
    `/manager/polls?userId=${userId}&sort=desc&id=${pollId}`,
  MANAGER_SET_POLL_VISIBILITY: "/manager/poll",

  //Members

  GET_HOUSE_MEMBERS: ({ id }) => `/manager/families/${id}`,
  CREATE_MEMBERS: "/manager/create-family",
  EDIT_MEMBERS: (id) => `/manager/families/${id}`,
  GET_TENANTS: ({ userId, sort = "desc" }) =>
    `/manager/manager-tenants/${userId}?sort=${sort}&status=Active&limit=99999999999`,
};
