import { Box, Text } from "@chakra-ui/react";
import { BsCheckCircle } from "react-icons/bs";
import { ImCancelCircle } from "react-icons/im";
import { _COLORS } from "../../../../app/constants";

export const Success = ({ onclickBack }) => {
  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      textAlign="center"
    >
      <Text color={_COLORS.brand} fontSize="21px" mb={18}>
        Successful, Access granted
      </Text>
      <BsCheckCircle
        color={_COLORS.brand}
        size={90}
        onClick={() => onclickBack(true)}
      />
      <Box position="absolute" right="10px" top="-20px">
        <ImCancelCircle
          onClick={() => onclickBack(true)}
          fontSize="1.5em"
          color={_COLORS.brand}
          cursor="pointer"
        />
      </Box>
    </Box>
  );
};
