import octagonImg from "../../../../assets/feather-alert-octagon.svg";
// import usersImg from "../../../../assets/feather-users.svg";
// import userImg from "../../../../assets/feather-user.svg";
import homeImg from "../../../../assets/feather-home.svg";
import { MdPerson } from "react-icons/md";
import { RiFirstAidKitFill } from "react-icons/ri";
import { IoIosPeople } from "react-icons/io";
import { BsFillHouseFill } from "react-icons/bs";

export const DashboardArray = [
  {
    id: 1,
    title: "Total Guests",
    background: "#FFFFFF",
    value: "0",
    key: "totalGuests",
    image: <MdPerson color="green" size={"1.5rem"} />,
    iconBg: "#DDFFDF",
    iconColor: "green",
  },
  {
    id: 2,
    title: "Emergency Requests",
    background: "#FFFFFF",
    value: "0",
    key: "totalEmergencyRequests",
    image: <RiFirstAidKitFill color="orange" size={"1.4rem"} />,
    iconBg: "#FFF3DB",
    iconColor: "orange",
  },
  {
    id: 3,
    title: "Total Residents",
    background: "#FFFFFF",
    value: "0",
    key: "totalUsers",
    image: <IoIosPeople color="#5596e0" size={"1.5rem"} />,
    iconBg: "#EDFAFF",
    iconColor: "blue",
  },
  {
    id: 4,
    title: "Total Houses",
    background: "#FFFFFF",
    value: 0,
    key: "totalHouses",
    image: <BsFillHouseFill color="purple" size={"1.4rem"} />,
    iconBg: "#f5f0f7",
    iconColor: "purple",
  },
  {
    id: 5,
    value: "0",
    key: "guestIn",
  },
  {
    id: 6,
    value: 0,
    key: "guestOut",
  },
];
