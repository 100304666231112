import { Box, Flex, HStack, Heading, Select, Text } from "@chakra-ui/react";
import React from "react";
import GuestCounterChart from "./GuestCounterChart";

export const GuestCounterCard = () => {
  return (
    <Box
      bg={"#FFFFFF"}
      borderRadius="10px"
      boxShadow="0px 3px 6px #0000003B"
      p={"15px"}
      w={["100%", "100%", "48.5%"]}
    >
      <Flex
        justify={["space-between"]}
        w={"100%"}
        align={"center"}
        flexWrap={["wrap", "wrap", "nowrap"]}
      >
        <Heading fontSize={"18px"} color={"#000"}>Guest Counter Graph</Heading>
        <HStack>
          <Flex align={"center"}>
            <Box h="11px" w="16px" rounded={"sm"} bg={"green"} mr="10px"></Box>
            <Flex justify={"space-between"} w="100%">
              <Text color={"#000"}>{"In"}</Text>
            </Flex>
          </Flex>
          <Flex align={"center"}>
            <Box h="11px" w="15px" rounded={"sm"} bg={"orange"} mr="10px"></Box>
            <Flex justify={"space-between"} w="100%">
              <Text color={"#000"}>{"Out"}</Text>
            </Flex>
          </Flex>
        </HStack>
        <Select
          placeholder="Monthly"
          color={"#3C7B79"}
          borderColor={"#3C7B79"}
          w={"150px"}
        >
          <option value="monthly">Monthly</option>
          <option value="daily">Daily</option>
        </Select>
      </Flex>
      <GuestCounterChart />
    </Box>
  );
};
