import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Text, Tr } from "@chakra-ui/react";
import { getStreets } from "./services/street";
import FullPageLoader from "../../../components/full-page-loader";
import { AiFillPlusCircle } from "react-icons/ai";
import "./index.css";
import CustomModal from "../../../components/customModal";
import CreateStreetForm from "../../../components/createStreetForm";
import SafeAreaContainer from "../../../components/SafeAreaContainer";
import BoxContainer from "../../../components/BoxContainer";
import CustomTable from "../../../components/CustomTable";
import StreetAction from "./components/StreetAction";

function Street() {
  const userId = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [street, setStreet] = useState({});
  const [refresh, setRefresh] = useState({});
  const [filteredStreets, setFilteredStreets] = useState([]);

  const [filter, setFilter] = useState({});
  const [streetTotal, setStreetTotal] = useState(0);

  useEffect(() => {
    setLoading(true);
    getStreets(userId)
      .then((result) => {
        const street = result?.data?.result?.data;
        setStreetTotal(street?.totalStreets);
        setStreet(street);
        setFilteredStreets(street?.streets);
      })
      .finally(() => setLoading(false));
  }, [refresh, userId]);

  return (
    <SafeAreaContainer bg="transparent">
      <BoxContainer mb="30px" flexDir={["column", "row"]} gap={["20px", "0"]}>
        <CustomTable.SearchInputField
          setFilter={setFilter}
          width={["100%", "100%", "25%", "50%"]}
        />

        <CustomModal
          title="Create Street"
          buttonText="Add Street"
          bg="#614285"
          mb={2}
          customBtn={CustomTable.BrandButton}
          customBtnIcon={AiFillPlusCircle}
        >
          <CreateStreetForm
            streetId={street?._id}
            onRefresh={() => setRefresh(!refresh)}
          />
        </CustomModal>
      </BoxContainer>

      <Box>
        {loading ? (
          <FullPageLoader />
        ) : (
          <>
            <CustomTable
              head={STREET_HEAD}
              filter={filter}
              DBdata={filteredStreets}
              DBsearchID={"streetName"}
            >
              {(data) =>
                data?.map((data, idx) => (
                  <Tr key={idx}>
                    <CustomTable.Td>
                      <Text {...CustomTable.style}>{idx + 1}</Text>
                    </CustomTable.Td>
                    <CustomTable.Td>
                      <CustomTable.LimitedText
                        limit={15}
                        {...CustomTable.style}
                      >
                        {data?.streetName || "nil"}
                      </CustomTable.LimitedText>
                    </CustomTable.Td>
                    <CustomTable.Td>
                      <CustomTable.LimitedText
                        limit={20}
                        {...CustomTable.style}
                      >
                        {data?.streetDescription || "nil"}
                      </CustomTable.LimitedText>
                    </CustomTable.Td>

                    <CustomTable.Td>
                      <StreetAction
                        street={data}
                        onRefresh={() => setRefresh({})}
                      />
                    </CustomTable.Td>
                  </Tr>
                ))
              }
            </CustomTable>
            <CustomTable.Pagination
              length={filteredStreets?.length}
              total={streetTotal}
              updateTable={async (page) =>
                getStreets(userId, page).then((result) => {
                  const street = result?.data?.result?.data;
                  setStreetTotal(street?.totalStreets);
                  setStreet(street);
                  setFilteredStreets(street?.streets);
                })
              }
            />
          </>
        )}
      </Box>
    </SafeAreaContainer>
  );
}

export default Street;

const STREET_HEAD = ["S/N", "STREET NAME", "STREET DETAILS", "ACTION"];
