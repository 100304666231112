import { Box, Heading, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CheckInForm } from "../../GuestCheckIn/component/CheckInForm";
import { Success } from "../../GuestCheckIn/component/Success";
import { ValidationConfirmation } from "../components/ValidationConfirmation";
import { getGuestCode } from "../../GuestCheckIn/service/guestValidationApi";
import {
  getEstateName,
  guestCheckOut,
} from "../service/managerGuestCheckOutApi";
import { _COLORS } from "../../../../app/constants";

export const ManagerGuestCheckOut = () => {
  const userId = useSelector((state) => state.auth.user);
  const [success, setSuccess] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState("");
  const [guestCode, setGuestCode] = useState("");
  const [invitedGuest, setInvitedGuest] = useState([]);
  // const [subscribed, setSubscribed] = useState()

  const handleSubmit = async (e, guestCode) => {
    e.preventDefault();
    const payload = { guestCode, userId };
    guestCheckOut(payload, setLoading, setConfirmationMsg, setSuccess);
  };
  const handleGetGuest = (e) => {
    e.preventDefault();

    getGuestCode(setLoading, userId, guestCode, setInvitedGuest, setConfirm);
  };

  const onclickBack = () => {
    setConfirmationMsg("");
    setGuestCode("");
    setSuccess(false);
    setConfirm(false);
  };

  return (
    <CheckoutContainer>
      {!success && (
        <Text fontSize=".9em" marginBottom="30px" color={_COLORS.light_black}>
          Please enter the validation code sent to you to sign out from this
          estate
        </Text>
      )}

      <Box className="dd" marginTop="40px">
        {!success && !confirm && (
          <CheckInForm
            handleGetGuest={handleGetGuest}
            loading={loading}
            guestCode={guestCode}
            setGuestCode={setGuestCode}
          />
        )}

        {!success && confirm && (
          <ValidationConfirmation
            onclickBack={onclickBack}
            guestCode={guestCode}
            loading={loading}
            invitedGuest={invitedGuest}
            setGuestCode={setGuestCode}
            handleSubmit={handleSubmit}
            setConfirmationMsg={setConfirmationMsg}
            confirmationMsg={confirmationMsg}
          />
        )}

        {success && <Success onclickBack={onclickBack} />}
      </Box>
    </CheckoutContainer>
  );
};

export const CheckoutContainer = ({ checkIn, children }) => {
  const [estateName, setEstateName] = useState("Our Estate");
  const userId = useSelector((state) => state.auth.user);

  useEffect(() => {
    getEstateName(setEstateName, userId);
  }, [userId]);

  return (
    <Box bg="#fff" borderRadius={"20px"} display="flex" py="30px" px="20px">
      <Box maxW="700px" color="#fff">
        <Text
          marginBottom="10px"
          color={_COLORS.black}
          fontSize={"1.5em"}
          fontWeight={"900"}
        >
          {checkIn ? "Welcome to " : "Goodbye from "}

          {estateName}
        </Text>

        {children}
      </Box>
    </Box>
  );
};
