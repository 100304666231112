import React, { useEffect, useState } from "react";
import SafeAreaContainer from "../../../../components/SafeAreaContainer";
import { Flex, Radio, Text } from "@chakra-ui/react";
import { BiChevronLeft } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { _COLORS, _GET_USER_PATH } from "../../../../app/constants";
import CustomTable from "../../../../components/CustomTable";
import { updateEmergency } from "../services/updateEmergency";
import { useSelector } from "react-redux";

export default function EmergencyDetails(props) {
  const userId = useSelector((state) => state.auth.user);

  const history = useHistory();

  const {
    _id,
    status,
    type,
    userId: {
      houseId: {
        name,
        estate,
        streetId: { streetName, streetDescription },
      },

      // _id: userId,
      tenantName,
      tenantPhone,
    },
  } = props.location.state || {};
  const [getStatus, setStatus] = useState("");

  // update status ones component mount
  useEffect(() => {
    setStatus(status);
  }, [status]);

  const borderStyles = {
    w: "100%",
    bg: "#fff",
    borderRadius: "10px",
    p: "20px",
    pb: "25px",
    flexDir: "column",
    // flex: "1",
  };
  const subHeaderStyle = {
    color: "#7E7C73",
    fontSize: ".9em",
    fontWeight: "500",
  };
  const contentStyle = {
    color: "#140005 ",
    // fontWeight: "600",
  };

  const handleUpdate = (e) => {
    const status = e.target.value?.toUpperCase();

    updateEmergency(userId, _id, { status }).then(() => window.history.back());
  };
  return (
    <SafeAreaContainer bg="transparent">
      {/* {JSON.stringify(props.location.state)} */}
      <Flex
        flexDir={["row"]}
        alignItems="center"
        gap="10px"
        mb="20px"
        cursor={"pointer"}
        onClick={() => history.push("/manager/emergency")}
      >
        <BiChevronLeft fontSize={"1.5em"} />
        <Text fontWeight={"500"}>Back</Text>
      </Flex>{" "}
      <Flex
        flexDir={["column", "column", "row"]}
        // alignItems={"flex-start"}
        gap="20px"
        alignItems="initial"
      >
        <Flex {...borderStyles}>
          <Text fontWeight="700" fontSize={".86em"} color="#000" mb="20px">
            Emergency Details
          </Text>

          <Text {...subHeaderStyle}>Alert From</Text>
          <Text {...contentStyle}>{tenantName || "N/A"}</Text>
          <br />
          <Text {...subHeaderStyle}>Phone Number</Text>
          <Text {...contentStyle}>{tenantPhone || "N/A "}</Text>
          <br />
          <Text {...subHeaderStyle}>House Member of</Text>
          <Text {...contentStyle}>{name || "N/A"}</Text>
          <br />
          <Text {...subHeaderStyle}>Street</Text>
          <Text {...contentStyle}>{streetDescription || "N/A"}</Text>

          <br />
          <Text {...subHeaderStyle}>House Number</Text>
          <Text {...contentStyle}>{estate?.[0]?.houseNumber || "N/A"}</Text>
          <br />
          <Text {...subHeaderStyle}>Emergency Type</Text>
          <Text {...contentStyle}>{type || "N/A"}</Text>
          <br />
          <CustomTable.BrandButton disabled={true} alignSelf="flex-start">
            Use Live Location
          </CustomTable.BrandButton>
        </Flex>

        <Flex w="100%" flexDir={"column"} gap="20px">
          {" "}
          <Flex {...borderStyles}>
            <Text fontWeight="700" fontSize={".86em"} color="#000" mb="20px">
              Resident's Details
            </Text>
            <Text {...subHeaderStyle}>Resident Name</Text>
            <Text {...contentStyle}>{tenantName || "N/A"}</Text>
            <br />

            <Text {...subHeaderStyle}>Phone Number</Text>
            <Text {...contentStyle}>{tenantPhone || "N/A"}</Text>
            <br />

            <Text {...subHeaderStyle}>Street Name</Text>
            <Text {...contentStyle}>{streetName || "N/A"}</Text>
            <br />

            <Text {...subHeaderStyle}>House Number</Text>
            <Text {...contentStyle}> {estate?.[0]?.houseNumber || "N/A"}</Text>
            <br />
          </Flex>
          {_GET_USER_PATH() === "manager" && (
            <Flex {...borderStyles}>
              <Text fontWeight="700" fontSize={".86em"} color="#000" mb="20px">
                Change Status
              </Text>

              {/* change radio button color */}
              <style>
                {`
                .css-1rggdzb[aria-checked=true], .css-1rggdzb[data-checked] {
                  background: #1c8c23;
                  border-color: #1c8c23;
                  color: #ffffff;
              }
                `}
              </style>
              <Flex justifyContent={"space-between"}>
                <Text color="#F96400AD">Pending</Text>
                <Radio
                  value="pending"
                  isChecked={getStatus?.toLocaleLowerCase() === "pending"}
                  onChange={handleUpdate}
                />
              </Flex>
              <br />
              <Flex justifyContent={"space-between"}>
                <Text color="#1D8C2387">Processing</Text>
                <Radio
                  value="processing"
                  isChecked={getStatus?.toLocaleLowerCase() === "processing"}
                  onChange={handleUpdate}
                />
              </Flex>
              <br />

              <Flex justifyContent={"space-between"}>
                <Text color="#060C3C87">Sorted</Text>
                <Radio
                  value="sorted"
                  isChecked={
                    getStatus?.toLocaleLowerCase() === "resolved" ||
                    getStatus?.toLocaleLowerCase() === "sorted"
                  }
                  onChange={handleUpdate}
                />
              </Flex>
            </Flex>
          )}
        </Flex>

        <Flex {...borderStyles}>
          <Text fontWeight="700" fontSize={".86em"} color="#000" mb="20px">
            House Members
          </Text>
          <Flex justifyContent={"space-between"}>
            <Text {...subHeaderStyle}>Alert From</Text>
            <Text {...subHeaderStyle}>1</Text>
          </Flex>

          <Text {...contentStyle}>...</Text>
          <br />

          <Text {...subHeaderStyle}>Phone Number</Text>
          <Text {...contentStyle}>...</Text>
          <br />
          <Text {...subHeaderStyle}>Relationship</Text>
          <Text {...contentStyle}>...</Text>
          <br />

          {/* ........ */}

          <Flex justifyContent={"space-between"}>
            <Text {...subHeaderStyle}>Alert From</Text>
            <Text {...subHeaderStyle}>2</Text>
          </Flex>

          <Text {...contentStyle}>...</Text>
          <br />

          <Text {...subHeaderStyle}>Phone Number</Text>
          <Text {...contentStyle}>...</Text>
          <br />
          <Text {...subHeaderStyle}>Relationship</Text>
          <Text {...contentStyle}>...</Text>
          <br />
        </Flex>
      </Flex>
    </SafeAreaContainer>
  );
}
