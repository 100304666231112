import { Box, useMediaQuery } from "@chakra-ui/react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { Suspense, useEffect, useState, lazy } from "react";
import { Navigation } from "../components/Nav/Navigation";
import { SideBar } from "../components/Layout/SideBar";
import GuestList from "../pages/Manager-Portal/GuestList";
import Emergency from "../pages/Manager-Portal/Emergency";
import Dashboard from "../pages/Manager-Portal/Dashboard";
import Notification from "../pages/Manager-Portal/Notification";
import { PROTECTED_PATHS } from "./constants";
import Street from "../pages/Manager-Portal/Street";
import EmergencyDetails from "../pages/Manager-Portal/Emergency/subPages/EmergencyDetails";
const EstateDue = lazy(() => import("../pages/Manager-Portal/EstateDue"));

const House = lazy(() => import("../pages/Manager-Portal/House"));
const Resident = lazy(() => import("../pages/Manager-Portal/Resident"));
const NewsEvents = lazy(() => import("../pages/Manager-Portal/news events"));
const EventRequest = lazy(() =>
  import("../pages/Manager-Portal/event request")
);
const ManagerCheckout = lazy(() =>
  import("../pages/Manager-Portal/GuestCheckOut")
);
const ManagerCheckin = lazy(() =>
  import("../pages/Manager-Portal/GuestCheckIn")
);
const PayoutHistory = lazy(() =>
  import("../pages/Manager-Portal/EstateDue/components/PayoutHistory")
);
const Polls = lazy(() => import("../pages/Manager-Portal/polls"));
const IssueReportsPage = lazy(() =>
  import("../pages/Manager-Portal/IssueReports")
);
const IssueReportDetails = lazy(() =>
  import("../pages/Manager-Portal/IssueReports/subPages/IssueReportDetails")
);
const GuestDetails = lazy(() =>
  import("../pages/Manager-Portal/GuestList/component/GuestDetails")
);
const ResidentDetails = lazy(() =>
  import("../pages/Manager-Portal/Resident/ResidentDetails")
);
const EstatePaymentId = lazy(() =>
  import("../pages/Manager-Portal/EstateDue/components/EstatePaymentId")
);

const {
  MANAGER_DASHBOARD,
  MANAGER_EMERGENCY,
  MANAGER_EMERGENCY_DETAILS,
  MANAGER_ISSUE_REPORTS,
  MANAGER_ISSUE_REPORT_DETAILS,
  MANAGER_GUEST_CHECK_IN,
  MANAGER_GUEST_CHECK_OUT,
  MANAGER_GUEST_LIST,
  MANAGER_NOTIFICATION,
  MANAGER_STREET,
  MANAGER_ESTATE_DUE,
  MANAGER_ESTATE_DUE_ID,
  MANAGER_HOUSE,
  MANAGER_RESIDENT,
  MANAGER_RESIDENT_DETAILS,
  MANAGER_NEWS_EVENTS,
  MANGER_EVENT_REQUESTS,
  MANAGER_PAYOUT_HISTORY,
  MANAGER_POLLS,
  MANAGER_GUEST_DETAILS,
} = PROTECTED_PATHS;

export function AuthenticatedManagerApp({ userType }) {
  const [toggleSide, setToggleSide] = useState(false);
  const [showSidBar, setShowSideBar] = useState(true);
  const [isMobileScreen] = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    if (isMobileScreen) {
      setToggleSide((initial) => !initial);
    }
  }, [isMobileScreen]);

  const handleToggle = () => {
    setToggleSide((initial) => !initial);
  };

  return (
    <Suspense fallback={<p></p>}>
      <Box className="App" display="flex">
        <Box className={`app-container`}>
          <Router>
            <Box
              className="app-sidebar"
              display={`${toggleSide ? "none" : "block"}`}
            >
              {showSidBar ? (
                <SideBar type="manager" toggle={handleToggle} />
              ) : (
                ""
              )}
            </Box>
            <Box className="app-right" width="100%">
              {showSidBar ? (
                <Navigation
                  toggle={handleToggle}
                  sideBarActive={toggleSide}
                  showSidBar={showSidBar}
                  type="manager"
                  userType={userType}
                />
              ) : (
                ""
              )}

              <Box
                width="100%"
                maxWidth="100%"
                mt={showSidBar ? "30px" : 0}
                padding={showSidBar ? ["5px", "10px", "20px"] : "0"}
              >
                <Switch>
                  <Route
                    path={MANAGER_ESTATE_DUE}
                    exact
                    component={EstateDue}
                  />
                  <Route
                    path={MANAGER_ESTATE_DUE_ID}
                    // exact
                    component={EstatePaymentId}
                  />

                  <Route path={MANAGER_DASHBOARD} exact component={Dashboard} />

                  <Route
                    path={MANAGER_GUEST_LIST}
                    exact
                    component={GuestList}
                  />
                  <Route
                    path={MANAGER_GUEST_DETAILS}
                    exact
                    component={GuestDetails}
                  />

                  <Route path={MANAGER_EMERGENCY} exact component={Emergency} />
                  <Route
                    path={MANAGER_EMERGENCY_DETAILS}
                    exact
                    component={EmergencyDetails}
                  />
                  <Route
                    path={MANAGER_ISSUE_REPORTS}
                    exact
                    component={IssueReportsPage}
                  />
                  <Route
                    path={MANAGER_ISSUE_REPORT_DETAILS}
                    exact
                    component={IssueReportDetails}
                  />

                  <Route path={MANAGER_RESIDENT} exact component={Resident} />
                  <Route
                    path={MANAGER_RESIDENT_DETAILS}
                    exact
                    component={ResidentDetails}
                  />

                  <Route
                    path={MANAGER_NOTIFICATION}
                    exact
                    component={Notification}
                  />

                  <Route path={MANAGER_STREET} exact component={Street} />
                  <Route path={MANAGER_HOUSE} exact component={House} />
                  <Route
                    path={MANAGER_NEWS_EVENTS}
                    exact
                    component={NewsEvents}
                  />

                  <Route
                    path={MANGER_EVENT_REQUESTS}
                    exact
                    component={EventRequest}
                  />
                  <Route path={MANAGER_GUEST_CHECK_IN} exact>
                    <ManagerCheckin
                      setShowSideBar={() => setShowSideBar(false)}
                    />
                  </Route>
                  <Route path={MANAGER_GUEST_CHECK_OUT} exact>
                    <ManagerCheckout
                      setShowSideBar={() => setShowSideBar(false)}
                    />
                  </Route>

                  <Route
                    path={MANAGER_PAYOUT_HISTORY}
                    exact
                    component={PayoutHistory}
                  />
                  <Route path={MANAGER_POLLS} exact component={Polls} />

                  <Route path={"/*"} exact component={Dashboard} />
                </Switch>
              </Box>
            </Box>
          </Router>
        </Box>
      </Box>
    </Suspense>
  );
}
